import { Button, Modal, Form } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import emotions, { compareEmotions } from "../data/emotions";

const EditUtteranceModal = ({ onSubmit, onCancel, utterance }) => {
    const { register, handleSubmit } = useForm();

    return (
        <Modal show={true} onHide={onCancel} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Modifier une phrase</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Form.Group className="col-sm-12 form-group">
                        <Form.Label htmlFor="utterance-utterance">Phrase</Form.Label>
                        <Form.Control {...register("utterance", { required: true })}
                            id="utterance-utterance"
                            type="text"
                            defaultValue={utterance?.utterance}
                        />
                    </Form.Group>
                    <Form.Group className="col-sm-12 form-group">
                        <Form.Label htmlFor="utterance-emotion">Émotion</Form.Label>
                        <Form.Select {...register("emotion")}
                            id="utterance-emotion"
                            data-style="py-0"
                            defaultValue={utterance?.emotion}
                        >
                            <option value="">Aucune</option>
                            {
                                Object.entries(emotions)
                                    .sort(([idA], [idB]) => compareEmotions(idA, idB))
                                    .map(([id, { label }]) => (
                                        <option key={id} value={id}>{label}</option>
                                    ))
                            }
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCancel}>Annuler</Button>
                    <Button type="submit" variant="primary">Confirmer</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditUtteranceModal;
