import {Button, Modal} from 'react-bootstrap'

const DeleteModal = ({show, onConfirm, onCancel}) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Confirmez-vous la suppression ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Annuler</Button>
                <Button variant="primary" onClick={onConfirm}>Confirmer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
