import { Dropdown } from 'react-bootstrap';
import CustomToggle from '../HopeUI/components/dropdowns'
import { connect } from 'react-redux';
import { getAccount, getAccounts } from '../store/api';
import setAccount from '../actions/api/setAccount';
import { useHistory, useLocation } from 'react-router-dom';
import { useOidc, useOidcUser } from '@axa-fr/react-oidc';

const mapStateToProps = (state) => {
    return {
        account: getAccount(state),
        accounts: getAccounts(state),
    };
};

const mapDispatchToProps = {
    setAccount,
};

const HeaderDropdown = ({ account, accounts, setAccount }) => {
    const { logout } = useOidc();
    const { oidcUser } = useOidcUser();

    const location = useLocation();
    const history = useHistory();

    const displayName =
        account && accounts.length === 1 ? `${account.companyName} - ${oidcUser?.name}` : oidcUser?.name;

    const switchAccount = (newAccount) => {
        history.push(location.pathname.includes('/dashboard/app/models') ? '/dashboard/app/models' : '/dashboard');
        setAccount(newAccount);
    };

    return (
        <>
            {account && accounts.length > 1 &&
                <Dropdown as="li" className="nav-item">
                    <Dropdown.Toggle as={CustomToggle} variant="nav-link d-flex align-items-center h-100" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="caption">
                            <h6 className="caption-title">{account.companyName}</h6>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-end overflow-scroll max-vh-75" aria-labelledby="navbarDropdown">
                        {accounts.length > 1 && accounts.map((a) => (
                            <Dropdown.Item key={a.idAccount} href="#" onClick={() => switchAccount(a)}>{a.companyName}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            }
            <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle as={CustomToggle} variant="nav-link d-flex align-items-center h-100" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="caption">
                        <h6 className="caption-title">{displayName}</h6>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="navbarDropdown">
                    <Dropdown.Item href="#" onClick={() => logout()}>Se déconnecter</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDropdown);
