import { getService } from "../../store/api";
import { getNotifications, getNotificationsController } from "../../store/notifications";
import { ABORT_NOTIFICATIONS, FETCH_NOTIFICATIONS__RESOLVE, SUBSCRIBE_NOTIFICATIONS } from "./types";

export const subscribeNotifications = (limit) => (dispatch, getState) => {
    const service = getService(getState());
    const controller = service.fetchEventSource(`v1/notification/live?limit=${limit}`, {
        onmessage(msg) {
            if (msg.event !== "notifications" || !msg.id) {
                return;
            }
            const json = Buffer.from(msg.data, "base64");
            const data = JSON.parse(json);

            const notifications = [...getNotifications(getState())];
            for (const notification of data) {
                if (notifications.find((n) => n.id === notification.id) === undefined) {
                    notifications.push(notification);
                }
            }
            notifications.sort((a, b) => b.id - a.id);

            dispatch({ type: FETCH_NOTIFICATIONS__RESOLVE, notifications, errors: {} });
        },
        onclose() {
            throw new Error('Notifications socket closed, reconnecting');
        },
    });
    dispatch({ type: SUBSCRIBE_NOTIFICATIONS, controller });
    return controller;
};

export const abortSubscribeNotifications = () => (dispatch, getState) => {
    const controller = getNotificationsController(getState());
    if (!controller) {
        return;
    }

    controller.abort();
    dispatch({ type: ABORT_NOTIFICATIONS });
};

export default subscribeNotifications;
