import { fetchEventSource } from '@microsoft/fetch-event-source';
import getAccessToken from './getAccessToken';
import getAccount from './getAccount';

const API_URL = process.env.REACT_APP_API_URL || "https://localhost:8000/api"

// ? Exemple utilisation
// import RequestService from '@services/RequestService';
// const service = new RequestService();
// let res = await service.fetchEndpoint( `exemple/${exempleId}`, 'PUT', data )

export default class RequestService {
    getAuthHeader() {
        const accessToken = getAccessToken();
        return `Bearer ${accessToken}`;
    }

    getTenant() {
        const accountId = getAccount();
        return `${accountId}`;
    }

    async fetchEndpoint(endpoint, method = 'GET', data = null, stringify, anonymous = false) {
        const headers = new Headers({
            Authorization: !anonymous && this.getAuthHeader(),
            'content-type': 'application/json',
            'X-Tenant-Id': this.getTenant(),
        })

        try {
            if (data === null) {
                const res = await fetch(`${API_URL}/${endpoint}`,
                    {
                        method: method,
                        headers: headers
                    })
                if (res.ok) {
                    return res;
                }
                else {
                    if (res.status === 403)
                        console.log("#### Problème de droits sur : " + res.url);
                    return Promise.reject(res);
                }
            }
            else {
                if (stringify) {
                    const res = await fetch(`${API_URL}/${endpoint}`,
                        {
                            method: method,
                            headers: headers,
                            body: JSON.stringify(data)
                        })

                    if (res.ok) {
                        return res;
                    }
                    else {
                        if (res.status === 403)
                            console.log("#### Problème de droits sur : " + res.url);
                        return Promise.reject(res);
                    }
                }
                else {
                    const res = await fetch(`${API_URL}/${endpoint}`,
                        {
                            method: method,
                            headers: headers,
                            body: data
                        })

                    if (res.ok) {
                        return res;
                    }
                    else {
                        return Promise.reject(res);
                    }
                }

            }
        } catch (error) {
            console.log(error);
        }
    }

    fetchEventSource(endpoint, handlers, method = 'GET', data = undefined, anonymous = false) {
        const controller = new AbortController();
        fetchEventSource(`${API_URL}/${endpoint}`, {
            ...handlers,
            fetch: (input, init) => window.fetch(input, {
                ...init,
                headers: {
                    ...input.headers,
                    Authorization: !anonymous && this.getAuthHeader(),
                    'content-type': 'application/json',
                    'X-Tenant-Id': this.getTenant(),
                },
            }),
            method,
            body: JSON.stringify(data),
            signal: controller.signal,
            openWhenHidden: true,
        });
        return controller;
    }

    async getErrors(response, defaultProperty) {
        const json = await response?.json?.().catch?.(() => { }) || { defaultProperty: response };
        const errors = json.errors ?? [{ property: defaultProperty, message: response.statusText }];
        return Object.fromEntries(errors.map(e => [e.property, e.message]));
    }
}
