import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { DELETE_VERSION__BEGIN, DELETE_VERSION__RESOLVE } from "./types";

const deleteVersion = (version) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    if (!model) {
        return null;
    }

    dispatch({ type: DELETE_VERSION__BEGIN, version });

    const service = getService(getState());
    await service.fetchEndpoint(`v1/model/${model.id}/version/${version.major}.${version.minor}`, 'DELETE');

    dispatch({ type: DELETE_VERSION__RESOLVE, version });
};

export default deleteVersion;
