import { SET_ACCOUNT, SET_ACCOUNTS, SET_ISRECONNECTING } from "../actions/api/types";
import RequestService from "../services/RequestService";

const getInitState = () => ({
    account: null,
    accounts: [],
    isReconnecting: false,
    service: new RequestService(),
});

const api = (state = getInitState(), action) => {
    switch (action.type) {
        case SET_ACCOUNT:
            return {
                ...state,
                account: action.account,
            };

        case SET_ACCOUNTS:
            return {
                ...state,
                accounts: action.accounts,
            };

        case SET_ISRECONNECTING:
            return {
                ...state,
                isReconnecting: action.isReconnecting,
            };

        default:
            return state;
    }
}

export const getAccount = (state) => state.api.account;
export const getAccounts = (state) => state.api.accounts;
export const getIsReconnecting = (state) => state.api.isReconnecting;
export const getService = (state) => state.api.service;

export default api;
