import { getSelectedVersion } from "../../store/versions";
import { INIT_REGULATION } from "./types";

const reset = () => async (dispatch, getState) => {
    const version = getSelectedVersion(getState());
    if (!version) {
        return;
    }
    dispatch({ type: INIT_REGULATION, actionTendencies: version['action_tendency_regulations'] });
};

export default reset;
