import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { ADD_UTTERANCE__BEGIN, ADD_UTTERANCE__RESOLVE } from "./types";

const addUtterance = utterance => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return null;
    }

    dispatch({ type: ADD_UTTERANCE__BEGIN, utterance });

    const service = getService(getState());
    let errors = {};
    let responseUtterance = null;
    try {
        const uri =
            `v1/model/${model.id}/` +
            `version/${version.major}.${version.minor}` +
            `/utterance/`
            ;
        const response = await service.fetchEndpoint(uri, 'POST', utterance, true);
        responseUtterance = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'utterance');
    }

    dispatch({ type: ADD_UTTERANCE__RESOLVE, utterance: responseUtterance, errors });
    return responseUtterance;
};

export default addUtterance;
