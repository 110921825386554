export const ADD_VERSION__BEGIN = 'ADD_VERSION__BEGIN';
export const ADD_VERSION__RESOLVE = 'ADD_VERSION__RESOLVE';
export const DELETE_VERSION__BEGIN = 'DELETE_VERSION__BEGIN';
export const DELETE_VERSION__RESOLVE = 'DELETE_VERSION__RESOLVE';
export const FETCH_VERSIONS__BEGIN = 'FETCH_VERSIONS__BEGIN';
export const FETCH_VERSIONS__RESOLVE = 'FETCH_VERSIONS__RESOLVE';
export const PREDICT_VERSION__BEGIN = 'PREDICT_VERSION__BEGIN';
export const PREDICT_VERSION__RESOLVE = 'PREDICT_VERSION__RESOLVE';
export const TRAIN_VERSION__RESOLVE = 'TRAIN_VERSION__RESOLVE';
export const TRAIN_VERSION__BEGIN = 'TRAIN_VERSION__BEGIN';
export const PUBLISH_VERSION__RESOLVE = 'PUBLISH_VERSION__RESOLVE';
export const PUBLISH_VERSION__BEGIN = 'PUBLISH_VERSION__BEGIN';
export const REFRESH_VERSION = 'REFRESH_VERSION';
export const SELECT_VERSION__BEGIN = 'SELECT_VERSION__BEGIN';
export const SELECT_VERSION__RESOLVE = 'SELECT_VERSION__RESOLVE';
export const SORT_VERSIONS = 'SORT_VERSIONS';
