import { getService } from "../../store/api";
import { ADD_MODEL__BEGIN, ADD_MODEL__RESOLVE } from "./types";

const addModel = model => async (dispatch, getState) => {
    dispatch({ type: ADD_MODEL__BEGIN, model });

    const service = getService(getState());
    let errors = {};
    let responseModel = null;
    try {
        const response = await service.fetchEndpoint('v1/model/', 'POST', model, true);
        responseModel = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'model');
    }

    dispatch({ type: ADD_MODEL__RESOLVE, model: responseModel, errors });
    return responseModel;
};

export default addModel;
