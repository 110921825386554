import { useEffect } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import emotions, { compareEmotions } from "../data/emotions";

const ModelTestContext = ({ context, setContext, onReset }) => {
    const { register, handleSubmit, reset, getValues } = useForm();

    const setEmotion = (emotion, value) => {
        setContext(context => context.map(e => e.emotion !== emotion ? e : {
            emotion,
            value: Math.max(0, Math.min(100, value)),
        }))
    };

    const deleteEmotion = (emotion) => {
        setContext(context => context.filter(e => e.emotion !== emotion));
    };

    const onSubmit = (formData) => {
        if (!formData?.emotion) {
            return;
        }
        setContext(context => [
            ...context.filter(e => e.emotion !== formData.emotion),
            {
                emotion: formData.emotion,
                value: 0,
            }
        ])
    };

    useEffect(() => {
        if (
            getValues('emotion') === '' ||
            context.find(e => e.emotion === getValues('emotion')) !== undefined
        ) {
            reset();
        }
    }, [context, getValues, reset]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex pt-1 mb-3">
                <Form.Select {...register("emotion")}>
                    {
                        Object.entries(emotions)
                            .filter(([id]) => context.map(e => e.emotion).find(emotion => emotion === id) === undefined)
                            .sort(([idA], [idB]) => compareEmotions(idA, idB))
                            .map(([id, { label }]) => <option key={id} value={id}>{label}</option>)
                    }
                </Form.Select>
                <Button type="submit" variant="primary" className="ms-2">Ajouter</Button>
                <Button type="button" variant="danger" className="ms-2" onClick={onReset}>Effacer</Button>
            </form>
            <ListGroup>
                {context.map(emotion => (
                    <ListGroup.Item key={emotion.emotion} className="py-1">
                        <Form.Group className="d-flex gap-1 align-items-center" controlId={"emotion" + emotion.emotion}>
                            <Form.Label className="m-0 flex-grow-1">{emotions[emotion.emotion].label}</Form.Label>
                            <div className="w-50 d-flex">
                                <Form.Control
                                    type="number"
                                    size="sm"
                                    value={emotion.value}
                                    step="0.1"
                                    className="w-0 flex-grow-1"
                                    onChange={e => setEmotion(emotion.emotion, parseFloat(e.target.value) || 0)}
                                />
                                <Button
                                    type="button"
                                    variant="danger"
                                    size="sm"
                                    onClick={() => deleteEmotion(emotion.emotion)}
                                >-</Button>
                            </div>
                        </Form.Group>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
    );
};

export default ModelTestContext;
