import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { PUBLISH_VERSION__BEGIN, PUBLISH_VERSION__RESOLVE } from "./types";

const publishVersion = (environment) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return null;
    }

    dispatch({ type: PUBLISH_VERSION__BEGIN, environment });

    const service = getService(getState());
    let errors = {};
    let publish = null;
    try {
        const uri =
            `v1/model/${model.id}/` +
            `version/${version.major}.${version.minor}` +
            `/publish`
            ;
        const response = await service.fetchEndpoint(uri, 'POST', environment, true);
        publish = await response.status;
    } catch (response) {
        errors = await service.getErrors(response, 'version');
    }

    dispatch({ type: PUBLISH_VERSION__RESOLVE, publish, errors });
    return publish;
};

export default publishVersion;
