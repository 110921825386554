export const ADD_MODEL__BEGIN = 'ADD_MODEL__BEGIN';
export const ADD_MODEL__RESOLVE = 'ADD_MODEL__RESOLVE';
export const DELETE_MODEL__BEGIN = 'DELETE_MODEL__BEGIN';
export const DELETE_MODEL__RESOLVE = 'DELETE_MODEL__RESOLVE';
export const DESELECT_MODEL = 'DESELECT_MODEL';
export const EDIT_MODEL__BEGIN = 'EDIT_MODEL__BEGIN';
export const EDIT_MODEL__RESOLVE = 'EDIT_MODEL__RESOLVE';
export const FETCH_MODELS__BEGIN = 'FETCH_MODELS__BEGIN';
export const FETCH_MODELS__RESOLVE = 'FETCH_MODELS__RESOLVE';
export const SELECT_MODEL__BEGIN = 'SELECT_MODEL__BEGIN';
export const SELECT_MODEL__RESOLVE = 'SELECT_MODEL__RESOLVE';
