import { getService } from "../../store/api";
import { FETCH_MODELS__BEGIN, FETCH_MODELS__RESOLVE } from "./types";

const fetchModels = (offset, limit) => async (dispatch, getState) => {
    dispatch({ type: FETCH_MODELS__BEGIN, offset, limit });

    const service = getService(getState());
    let errors = {};
    let models = [];
    let totalCount = 0;
    try {
        let uri = `v1/model/?offset=${offset}`;
        if (limit > 0) {
            uri += `&limit=${limit}`;
        }
        const response = await service.fetchEndpoint(uri, 'GET');
        totalCount = response.headers.get('x-total-count');
        models = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'models');
    }

    dispatch({ type: FETCH_MODELS__RESOLVE, models, errors, totalCount });
    return models;
};

export default fetchModels;
