import { getRegulationActionTendencies } from "../../store/regulation";
import { SET_INPUTS } from "./types";

const setPoint = (code, emotion, pointIndex, x, y) => async (dispatch, getState) => {
    const actionTendency = getRegulationActionTendencies(getState()).find(t => t.code === code);

    x = Math.max(0, Math.min(100, x));
    y = Math.max(0, Math.min(100, y));

    const inputs = actionTendency.inputs.map((i) => {
        if (i.emotion !== emotion) return i;

        const point = { x, y };
        if (pointIndex > 0) {
            point.x = Math.max(point.x, i.points[pointIndex - 1].x);
        }
        if (pointIndex < i.points.length - 1) {
            point.x = Math.min(point.x, i.points[pointIndex + 1].x);
        }
        const points = i.points.map((p, index) => index !== pointIndex ? p : point);

        return { ...i, points };
    });

    dispatch({ type: SET_INPUTS, code, inputs });
};

export default setPoint;
