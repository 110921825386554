import { getService } from "../../store/api";
import { EDIT_MODEL__BEGIN, EDIT_MODEL__RESOLVE } from "./types";

const editModel = (id, model) => async (dispatch, getState) => {
    dispatch({ type: EDIT_MODEL__BEGIN, id, model });

    const service = getService(getState());
    let errors = {};
    let responseModel = null;
    try {
        const response = await service.fetchEndpoint(`v1/model/${id}`, 'PUT', model, true);
        responseModel = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'model');
    }

    dispatch({ type: EDIT_MODEL__RESOLVE, model: responseModel, errors });
    return responseModel;
};

export default editModel;
