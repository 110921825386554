import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import refreshVersion from "../actions/versions/refresh";
import trainVersion from "../actions/versions/train";
import ModelTrainModal from "./ModelTrainModal";

const mapDispatchToProps = {
    refreshVersion,
    trainVersion,
};

const ModelTrainButton = ({ refreshVersion, trainVersion }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [errors, setErrors] = useState({});

    const startTrain = (force = false) => {
        if (isFetching) {
            return;
        }
        setIsFetching(true);
        trainVersion(force)
            .then(({ train, errors }) => {
                if (train === 202) {
                    refreshVersion();
                }
                setErrors(errors);
            })
            .finally(() => setIsFetching(false));
    };

    const forceTrain = () => {
        startTrain(true);
        setErrors({});
    };

    const cancelTrain = () => {
        setErrors({});
    };

    return (
        <>
            <Button
                size="sm"
                className="position-relative"
                onClick={() => startTrain()}
                disabled={isFetching}
            >
                <span className={isFetching ? 'invisible' : ''}>Entrainer</span>
                <span className={'position-absolute translate-middle top-50 start-50'}>
                    <Spinner
                        animation="border"
                        variant="white"
                        role="status"
                        size="sm"
                        className={!isFetching ? 'invisible' : ''}
                    />
                </span>
            </Button>
            <ModelTrainModal
                show={Object.keys(errors).length > 0}
                onConfirm={forceTrain}
                onCancel={cancelTrain}
                errors={errors}
            />
        </>
    );
};

export default connect(null, mapDispatchToProps)(ModelTrainButton);
