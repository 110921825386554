import { useEffect } from "react";
import { Accordion, Button, Form, ListGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import deleteInput from "../actions/regulation/deleteInput";
import deletePoint from "../actions/regulation/deletePoint";
import insertPoint from "../actions/regulation/insertPoint";
import setPoint from "../actions/regulation/setPoint";
import setPoints from "../actions/regulation/setPoints";
import emotions, { compareEmotions } from "../data/emotions";

const mapDispatchToProps = {
    deletePoint,
    deleteInput,
    insertPoint,
    setPoint,
    setPoints,
};

const RegulationInputs = ({ actionTendency, deletePoint, deleteInput, insertPoint, setPoint, setPoints }) => {
    const { register, reset, getValues, handleSubmit } = useForm();

    const onSubmit = (formData) => {
        if (!formData?.emotion) {
            return;
        }
        setPoints(actionTendency.code, formData.emotion, [{ x: 0, y: 0 }, { x: 100, y: 100 }]);
    };

    useEffect(() => {
        if (
            getValues('emotion') === '' ||
            actionTendency.inputs.find(input => input.emotion === getValues('emotion')) !== undefined
        ) {
            reset();
        }
    }, [actionTendency, getValues, reset]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex my-3">
                <Form.Select {...register("emotion")}>
                    {
                        Object.entries(emotions)
                            .filter(([id]) => actionTendency.inputs.map(input => input.emotion).find(emotion => emotion === id) === undefined)
                            .sort(([idA], [idB]) => compareEmotions(idA, idB))
                            .map(([id, { label }]) => <option key={id} value={id}>{label}</option>)
                    }
                </Form.Select>
                <Button type="submit" variant="primary" className="ms-2">Ajouter</Button>
            </form>
            <Accordion alwaysOpen>
                {actionTendency.inputs.map(input => (
                    <Accordion.Item key={input.emotion} eventKey={input.emotion}>
                        <div className="d-flex">
                            <Accordion.Button className="flex-grow-1 px-3 py-0">{emotions[input.emotion].label}</Accordion.Button>
                            <Button
                                type="button"
                                variant="danger"
                                size="sm"
                                onClick={() => deleteInput(actionTendency.code, input.emotion)}
                            >-</Button>
                        </div>
                        <Accordion.Body>
                            <ListGroup>
                                {input.points.map((p, index) => (
                                    <ListGroup.Item key={index} className="d-flex gap-1 p-1">
                                        <Form.Control
                                            type="number"
                                            size="sm"
                                            value={p.x}
                                            step="0.05"
                                            onChange={e => setPoint(actionTendency.code, input.emotion, index, e.target.value, p.y)}
                                        />
                                        <Form.Control
                                            type="number"
                                            size="sm"
                                            value={p.y}
                                            step="0.05"
                                            onChange={e => setPoint(actionTendency.code, input.emotion, index, p.x, e.target.value)}
                                        />
                                        <Button
                                            type="button"
                                            variant="danger"
                                            size="sm"
                                            onClick={() => deletePoint(actionTendency.code, input.emotion, index)}
                                        >-</Button>
                                    </ListGroup.Item>
                                ))}
                                <Button
                                    type="button"
                                    variant="primary"
                                    className="align-self-end mt-3"
                                    size="sm"
                                    onClick={() => insertPoint(actionTendency.code, input.emotion, 100, 100)}
                                >Ajouter</Button>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
};

export default connect(null, mapDispatchToProps)(RegulationInputs);
