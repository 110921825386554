import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Card from '../HopeUI/components/Card'
import DeleteModal from '../components/DeleteModal'
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { getModels, getModelsErrors, getModelsTotalCount } from '../store/models'
import deleteModel from '../actions/models/delete'
import fetchModels from '../actions/models/fetch'
import { getAccount } from '../store/api'
import Alert from '../components/Alert'

const mapStateToProps = (state) => {
	return {
		account: getAccount(state),
		errors: getModelsErrors(state),
		models: getModels(state),
		totalCount: getModelsTotalCount(state),
	};
};

const mapDispatchToProps = {
	deleteModel,
	fetchModels,
};

const ModelList = ({ account, errors, models, totalCount, fetchModels, deleteModel }) => {
	const history = useHistory();

	const modelsPerPage = 20;
	const pageCount = Math.ceil(totalCount / modelsPerPage);

	// Modal
	const [deletingModel, setDeletingModel] = useState(null);

	const onConfirmDelete = () => {
		deleteModel(deletingModel);
		setDeletingModel(null);
	};

	const onCancelDelete = () => {
		setDeletingModel(null);
	};

	const handlePageClick = (event) => {
		const newOffset = event.selected * modelsPerPage;
		fetchModels(newOffset, modelsPerPage);
	};

	useEffect(() => {
		fetchModels(0, modelsPerPage);
	}, [account, fetchModels]);

	return (
		<Row>
			<Col sm="12">
				<div className="d-flex justify-content-between align-items-center">
					<h4 className="my-3">Liste des modèles</h4>
					<Button type="submit" variant="primary ms-4" size="sm" onClick={() => history.push('model-add')}>Ajouter</Button>
				</div>
				<Card>
					<Card.Body className="px-0">
						<div className="table-responsive">
							<table id="model-list-table" className="table table-striped" role="grid" data-toggle="data-table">
								<thead>
									<tr className="light">
										<th>Modèle</th>
										<th>Modèle parent</th>
										<th>Crée le</th>
										<th min-width="100px">Actions</th>
									</tr>
								</thead>
								<tbody>
									{
										models.map((model) => (
											<tr key={model.id}>
												<td>
													<Link to={`models/${model.id}`}>{model.name}</Link>
												</td>
												<td>
													{model.base_model &&
														<Link to={`models/${model.base_model.id}`}>
															{model.base_model.name}
														</Link>
													}
												</td>
												<td>{new Date(model.created_at).toLocaleDateString()}</td>
												<td>
													<div className="flex align-items-center list-user-action">
														<Link to={`models/${model.id}`} className="btn btn-sm btn-icon btn-info" data-toggle="tooltip" data-placement="top" title="Details" >
															<span className="btn-inner">
																<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fillRule="evenodd" clipRule="evenodd" d="M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M11.9946 16V12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M11.9896 8.2041H11.9996" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																</svg>
															</span>
														</Link>
														{' '}
														<Link to={`models/${model.id}/edit`} className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="Modifier">
															<span className="btn-inner">
																<svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																</svg>
															</span>
														</Link>
														{' '}
														<div onClick={() => setDeletingModel(model)} className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="Supprimer">
															<span className="btn-inner">
																<svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
																	<path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
																</svg>
															</span>
														</div>
													</div>
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
						<div className='d-flex justify-content-center pt-4'>
							<nav aria-label="Pagination">
								<ReactPaginate
									breakLabel="..."
									nextLabel="»"
									onPageChange={handlePageClick}
									pageRangeDisplayed={5}
									pageCount={pageCount}
									previousLabel="«"
									renderOnZeroPageCount={null}
									className="pagination"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									activeClassName="page-item active"
									disabledClassName="page-item disabled"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									breakClassName="page-item"
									breakLinkClassName="page-link"
								/>
							</nav>
						</div>
						<Alert message={errors.models} />
					</Card.Body>
				</Card>
				<DeleteModal show={!!deletingModel} onConfirm={onConfirmDelete} onCancel={onCancelDelete} />
			</Col>
		</Row>
	)

}

export default connect(mapStateToProps, mapDispatchToProps)(ModelList);
