import Color from "color";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Flatten from '@flatten-js/core';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import { } from 'chart.js/auto';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import DragdataPlugin from 'chartjs-plugin-dragdata';
import { getElementAtEvent, Scatter } from 'react-chartjs-2';
import { Button, Card, Dropdown } from "react-bootstrap";
import { Menu, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import RegulationInputs from "./RegulationInputs";
import RegulationOutputs from "./RegulationOutputs";
import tendencies from "../data/tendencies";
import deleteActionTendency from "../actions/regulation/deleteActionTendency";
import insertPoint from "../actions/regulation/insertPoint";
import deletePoint from "../actions/regulation/deletePoint";
import setPoint from "../actions/regulation/setPoint";
import emotions from "../data/emotions";
import deleteInput from "../actions/regulation/deleteInput";

Interaction.modes.interpolate = Interpolate;

const moveTooltip = (chart, native, datasetIndex, index, value) => {
    const OFFSET = 0.0001;
    const lastIndex = chart.data.datasets[datasetIndex].data.length - 1;
    const tooltipX =
        value.x >= value.maxX || index === lastIndex ? Math.min(value.x, value.maxX) - OFFSET :
            value.x <= value.minX || index === 0 ? Math.max(value.x, value.minX) + OFFSET :
                value.x;

    const event = {
        type: 'mousemove',
        chart,
        x: chart.scales.x.getPixelForValue(tooltipX),
        y: chart.scales.y.getPixelForValue(value.y),
        native,
        stop: true,
    };

    // Prevent the tooltip from moving back to its original position
    chart._lastEvent = event;

    const args = {
        replay: false,
        inChartArea: true,
        cancelable: false,
        event,
    };
    const filter = (plugin) => ['tooltip', 'crosshair'].includes(plugin.plugin.id);
    chart.notifyPlugins('afterEvent', args, filter);
};

const findNearestDataset = (chart, x, y) => {
    let distance = null;
    let datasetIndex;
    for (let i = 0; i < chart.data.datasets.length; ++i) {
        const dataset = chart.data.datasets[i];
        for (let j = 0; j < dataset.data.length; ++j) {
            const startPoint = dataset.data[j];
            const endPoint = dataset.data[j + 1] ?? startPoint;
            const segment = Flatten.segment(Flatten.point(startPoint.x, startPoint.y), Flatten.point(endPoint.x, endPoint.y));
            const [d] = Flatten.point(x, y).distanceTo(segment);
            if (distance === null || d < distance) {
                datasetIndex = i;
                distance = d;
            }
        }
    }
    return datasetIndex;
};

const mapDispatchToProps = {
    deleteActionTendency,
    deleteInput,
    deletePoint,
    insertPoint,
    setPoint,
};

const RegulationCard = ({ actionTendency, deleteActionTendency, deleteInput, deletePoint, insertPoint, setPoint }) => {
    const MENU_ID = `chart-menu-${actionTendency.code}`;

    const [contextMenu, setContextMenu] = useState(null);
    const { show: showMenu } = useContextMenu({
        id: MENU_ID,
    });
    const handleContextMenu = (event) => {
        event.preventDefault();
        const chart = chartRef.current;

        const canvasPosition = getRelativePosition(event, chart);
        const eventValue = {
            x: +chart.scales.x.getValueForPixel(canvasPosition.x).toFixed(1),
            y: +chart.scales.y.getValueForPixel(canvasPosition.y).toFixed(1),
        };

        const point = getElementAtEvent(chart, event)[0];
        const datasetIndex = point?.datasetIndex ?? findNearestDataset(chart, eventValue.x, eventValue.y);

        if (datasetIndex === undefined) return;
        const dataset = chart.data.datasets[datasetIndex];
        const value = point ? dataset.data[point.index] : eventValue;

        const handleAddPoint = () => insertPoint(actionTendency.code, dataset.emotion, value.x, value.y);
        const handleDeletePoint = () => deletePoint(actionTendency.code, dataset.emotion, point.index);
        const handleDeleteEmotion = () => deleteInput(actionTendency.code, dataset.emotion);

        setContextMenu(
            <>
                <Dropdown.Menu show onContextMenu={e => e.preventDefault()}>
                    <Dropdown.Header>
                        <span style={{ color: dataset.backgroundColor }}>
                            {dataset.label}
                        </span>
                        {' '}(Valeur: {value.x}, Score: {value.y})
                    </Dropdown.Header>
                    {!point && <Dropdown.Item onClick={handleAddPoint}>Ajouter un point</Dropdown.Item>}
                    {point && <Dropdown.Item onClick={handleDeletePoint}>Supprimer le point</Dropdown.Item>}
                    <Dropdown.Item onClick={handleDeleteEmotion}>Supprimer l'émotion</Dropdown.Item>
                </Dropdown.Menu>
            </>
        );
        showMenu(event);
    };

    const handleDoubleClick = (event) => {
        const chart = chartRef.current;

        const point = getElementAtEvent(chart, event)[0];
        if (point) {
            deletePoint(actionTendency.code, chart.data.datasets[point.datasetIndex].emotion, point.index);
            return;
        }

        const canvasPosition = getRelativePosition(event, chart);
        const eventValue = {
            x: +chart.scales.x.getValueForPixel(canvasPosition.x).toFixed(1),
            y: +chart.scales.y.getValueForPixel(canvasPosition.y).toFixed(1),
        };
        const datasetIndex = findNearestDataset(chart, eventValue.x, eventValue.y);
        if (datasetIndex === undefined) return;

        insertPoint(actionTendency.code, chart.data.datasets[datasetIndex].emotion, eventValue.x, eventValue.y);
    };

    const chartRef = useRef(null);

    const [options] = useState({
        aspectRatio: 1,
        maintainAspectRatio: true,
        scales: {
            x: { min: 0, max: 100, ticks: { stepSize: 10, }, },
            y: { min: 0, max: 100, ticks: { stepSize: 10, }, },
        },
        animation: {
            duration: 0,
        },
        plugins: {
            dragData: {
                round: 1,
                showTooltip: false,
                dragX: true,
                onDragStart: (e, element) => {
                },
                onDrag: (e, datasetIndex, index, value) => {
                    value.x = Math.max(value.minX, Math.min(value.maxX, value.x));
                    value.y = Math.max(value.minY, Math.min(value.maxY, value.y));
                    moveTooltip(chartRef.current, e, datasetIndex, index, value);
                },
                onDragEnd: (e, datasetIndex, index, value) => {
                    const chart = chartRef.current;
                    setPoint(actionTendency.code, chart.data.datasets[datasetIndex].emotion, index, value.x, value.y);
                    moveTooltip(chart, e, datasetIndex, index, value);
                },
            },
            crosshair: {
                sync: { enabled: false, },
                zoom: { enabled: false, },
                snap: { enabled: false, },
                line: { color: 'grey', }
            },
            tooltip: {
                animation: false,
                mode: "interpolate",
                intersect: false,
                callbacks: {
                    title: function (a, d) {
                        return a[0].element.x.toFixed(1);
                    },
                    label: function (d) {
                        return (
                            d.chart.data.datasets[d.datasetIndex].label + ": " + d.element.y.toFixed(1)
                        );
                    }
                },
            },
            legend: {
                position: 'bottom',
            },
        },
    });

    const [data, setData] = useState({
        datasets: [],
    });

    useEffect(() => {
        setData(data => ({
            ...data,
            datasets: actionTendency.inputs.map(i => ({
                label: emotions[i.emotion].label,
                emotion: i.emotion,
                data: i.points.map((p, index) => ({
                    ...p,
                    minX: index > 0 ? i.points[index - 1].x : 0,
                    maxX: index < i.points.length - 1 ? i.points[index + 1].x : 100,
                    minY: 0,
                    maxY: 100,
                })),
                backgroundColor: (new Color(emotions[i.emotion]?.color ?? 'blue')).hex(),
                borderColor: (new Color(emotions[i.emotion]?.color ?? 'blue')).hex(),
                interpolate: true,
                pointRadius: 5,
                pointHoverRadius: 10,
                pointHitRadius: 15,
                showLine: true,
            })),
        }));
    }, [actionTendency, actionTendency.inputs, setPoint]);

    return (
        <Card id={actionTendency.code}>
            <Button
                type="button"
                variant="danger"
                className="position-absolute end-0"
                size="sm"
                onClick={() => deleteActionTendency(actionTendency.code)}
            >-</Button>
            <Card.Body>
                <Card.Title className="text-center">{tendencies[actionTendency.code].label}</Card.Title>
                <Scatter
                    ref={chartRef}
                    options={options}
                    data={data}
                    plugins={[DragdataPlugin, CrosshairPlugin]}
                    onContextMenu={handleContextMenu}
                    onDoubleClick={handleDoubleClick}
                />
                <Menu id={MENU_ID} style={{ minWidth: 0 }}>
                    {contextMenu}
                </Menu>
                <hr />
                <Card.Subtitle className="text-center">Entrées</Card.Subtitle>
                <RegulationInputs actionTendency={actionTendency} />
                <hr />
                <Card.Subtitle className="text-center">Sorties</Card.Subtitle>
                <RegulationOutputs actionTendency={actionTendency} />
            </Card.Body>
        </Card>
    );
};

export default connect(null, mapDispatchToProps)(RegulationCard);
