import { getService } from "../../store/api";
import { SEEN_NOTIFICATION__BEGIN, SEEN_NOTIFICATION__RESOLVE } from "./types";

const seenNotification = (notification) => async (dispatch, getState) => {
    dispatch({ type: SEEN_NOTIFICATION__BEGIN, notification });

    const service = getService(getState());
    let errors = {};
    let result = null;
    try {
        await service.fetchEndpoint(`v1/notification/${notification.id}/seen`, 'POST');
        result = notification;
    } catch (response) {
        errors = await service.getErrors(response, 'notifications');
    }

    dispatch({ type: SEEN_NOTIFICATION__RESOLVE, notification: result, errors });
    return result;
};

export default seenNotification;
