import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { FaExclamation } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import UtteranceAddRow from './UtteranceAddRow';
import DeleteModal from './DeleteModal';
import EditUtteranceModal from '../components/EditUtteranceModal'
import { getUtterances, getUtterancesTotalCount } from '../store/utterances';
import fetchUtterances from '../actions/utterances/fetch';
import editUtterance from '../actions/utterances/edit';
import deleteUtterance from '../actions/utterances/delete';
import { connect } from 'react-redux';
import { getSelectedVersion } from '../store/versions';
import { useTable, useSortBy } from 'react-table'
import { Spinner } from "react-bootstrap";
import emotions from '../data/emotions';
import { invalidateUtteranceScore, scoreNextUtterance } from '../actions/utterances/score';
import { getSelectedModel } from '../store/models';

const mapStateToProps = (state) => {
    return {
        model: getSelectedModel(state),
        selectedVersion: getSelectedVersion(state),
        utterances: getUtterances(state),
        totalCount: getUtterancesTotalCount(state),
    };
};

const mapDispatchToProps = {
    fetchUtterances,
    editUtterance,
    deleteUtterance,
    invalidateUtteranceScore,
    scoreNextUtterance,
};

const ModelUtteranceList = ({
    model,
    selectedVersion,
    utterances,
    totalCount,
    fetchUtterances,
    editUtterance,
    deleteUtterance,
    invalidateUtteranceScore,
    scoreNextUtterance,
}) => {
    const [deletingUtterance, setDeletingUtterance] = useState(null);
    const [editingUtterance, setEditingUtterance] = useState(null);

    // Pagination
    const utterancesPerPage = 20;
    const pageCount = Math.ceil(totalCount / utterancesPerPage);


    // react-table
    const columns = useMemo(
        () => [
            {
                Header: 'Phrase',
                accessor: 'utterance',
            },
            {
                Header: 'Émotion',
                accessor: 'emotion',
            },
            {
                Header: 'Score',
                accessor: 'score',
                disableSortBy: true
            }
        ],
        []
    );
    const data = useMemo(() => [], []);
    const { getTableProps, getTableBodyProps, headerGroups, state: { sortBy } } = useTable({ columns, data, manualSorting: true, disableMultiSort: true }, useSortBy);

    useEffect(() => {
        scoreNextUtterance();
    }, [utterances, scoreNextUtterance]);

    useEffect(() => {
        fetchUtterances(0, utterancesPerPage, sortBy);
    }, [model?.id, selectedVersion?.major, selectedVersion?.minor, fetchUtterances, sortBy]);

    const handlePageClick = async (event) => {
        const newOffset = event.selected * utterancesPerPage;
        fetchUtterances(newOffset, utterancesPerPage, sortBy);
    };

    const onCancelEdit = () => {
        setEditingUtterance(null);
    };

    const onSubmitEdit = (formData) => {
        const body = {
            utterance: formData.utterance,
            emotion: formData.emotion || null,
        };
        editUtterance(editingUtterance.id, body).then((result) => {
            if (result !== null) {
                invalidateUtteranceScore(editingUtterance);
                setEditingUtterance(null);
            }
        });
    };

    const onConfirmDelete = () => {
        deleteUtterance(deletingUtterance);
        setDeletingUtterance(null);
    };

    const onCancelDelete = () => {
        setDeletingUtterance(null);
    };

    return (
        <>
            <div className="table-responsive">
                <table id="utterance-list-table" className="table table-striped" role="grid" data-toggle="data-table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="light">
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                    : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                                    </svg>
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                                <th>Actions</th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            utterances.map((utterance) => (
                                <tr key={utterance.id}>
                                    <td className="text-wrap text-break">{utterance.utterance}</td>
                                    <td>{emotions[utterance.emotion]?.label || 'Aucune'}</td>
                                    <td>{typeof utterance.score === 'number' ? (
                                        emotions[utterance.scoredEmotion]?.label ? (
                                            `${utterance.score} (${emotions[utterance.scoredEmotion]?.label})`
                                            ) : (
                                                utterance.score
                                            )
                                        ) : utterance.scoringFailed !== true ? (
                                            <Spinner animation="border" variant="primary" role="status" size='sm'>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : (
                                            <FaExclamation style={{ color: 'red' }} />
                                        )}
                                    </td>
                                    <td>
                                        <div className="flex align-items-center list-user-action">
                                            <Link onClick={() => setEditingUtterance(utterance)} className="btn btn-sm btn-icon btn-info" data-toggle="tooltip" data-placement="top" title="" aria-label="Edit" data-original-title="Edit" to="#">
                                                <span className="btn-inner">
                                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            </Link>{' '}
                                            <Link onClick={() => setDeletingUtterance(utterance)} className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" aria-label="Delete" data-original-title="Delete" to="#">
                                                <span className="btn-inner">
                                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            </Link>{' '}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        <DeleteModal show={!!deletingUtterance} onConfirm={onConfirmDelete} onCancel={onCancelDelete} />
                        {!!editingUtterance && <EditUtteranceModal onSubmit={onSubmitEdit} onCancel={onCancelEdit} utterance={editingUtterance} />}
                        <UtteranceAddRow />
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center pt-4'>
                <nav aria-label="Pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="»"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="«"
                        renderOnZeroPageCount={null}
                        className="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="page-item active"
                        disabledClassName="page-item disabled"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </nav>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelUtteranceList);
