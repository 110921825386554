import { getRegulationActionTendencies } from "../../store/regulation";
import { SET_INPUTS } from "./types";

const setPoints = (code, emotion, points) => async (dispatch, getState) => {
    const actionTendency = getRegulationActionTendencies(getState()).find(t => t.code === code);

    const found = actionTendency.inputs.find(i => i.emotion === emotion);
    const newInput = {
        emotion,
        points,
    };

    const inputs =
        found === undefined ?
            [...actionTendency.inputs, newInput] :
            actionTendency.inputs.map(i => i !== found ? i : newInput);

    dispatch({ type: SET_INPUTS, code, inputs });
};

export default setPoints;
