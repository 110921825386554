import { useState } from "react";
import { Button } from "react-bootstrap";
import ModelTestModal from "./ModelTestModal";

const ModelTestButton = () => {
    const [show, setShow] = useState(false);
    return (
        <>
            <Button variant="outline-primary" size="sm" onClick={() => setShow(true)}>Tester</Button>
            <ModelTestModal
                show={show}
                onHide={() => setShow(false)}
            />
        </>
    );
};

export default ModelTestButton;
