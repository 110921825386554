import { getService } from "../../store/api";
import { DELETE_MODEL__BEGIN, DELETE_MODEL__RESOLVE } from "./types";

const deleteModel = model => async (dispatch, getState) => {
    dispatch({ type: DELETE_MODEL__BEGIN, model });

    const service = getService(getState());
    await service.fetchEndpoint(`v1/model/${model.id}`, 'DELETE');

    dispatch({ type: DELETE_MODEL__RESOLVE, model });
};

export default deleteModel;
