import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { FETCH_VERSIONS__BEGIN, FETCH_VERSIONS__RESOLVE, SORT_VERSIONS } from "./types";

const fetchVersions = () => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    if (!model) {
        return [];
    }

    dispatch({ type: FETCH_VERSIONS__BEGIN });

    const service = getService(getState());
    let errors = {};
    let versions = [];
    try {
        const response = await service.fetchEndpoint(`v1/model/${model.id}/version/`, 'GET');
        versions = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'versions');
    }

    dispatch({ type: FETCH_VERSIONS__RESOLVE, versions, errors });
    dispatch({ type: SORT_VERSIONS });
    return versions;
};

export default fetchVersions;
