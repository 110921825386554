//router
import IndexRouters from "./HopeUI/router/index"

function App() {
  return (
    <div className="App">
      <IndexRouters />
    </div>
  );
}

export default App;
