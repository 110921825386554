import { SELECT_MODEL__BEGIN } from "../actions/models/types";
import { ADD_ACTION_TENDENCY, DELETE_ACTION_TENDENCY, INIT_REGULATION, SAVE_REGULATION__BEGIN, SAVE_REGULATION__RESOLVE, SET_INPUTS, SET_OUTPUTS } from "../actions/regulation/types";
import { SELECT_VERSION__BEGIN } from "../actions/versions/types";
import emotions from "../data/emotions";
import tendencies from "../data/tendencies";

const getInitState = () => ({
    actionTendencies: [],
    dirty: false,
    isFetching: false,
    errors: {},
});

const regulation = (state = getInitState(), action) => {
    switch (action.type) {
        case INIT_REGULATION:
            return {
                ...state,
                dirty: state.isFetching,
                errors: {},
                actionTendencies: action.actionTendencies
                    .filter(tendency => !!tendencies[tendency.code])
                    .map(tendency => ({
                        code: tendency.code,
                        inputs: tendency.emotion_inputs
                            .filter(input => !!emotions[input.emotion]),
                        outputs: tendency.emotion_outputs
                            .filter(output => !!emotions[output.emotion]),
                    })),
            };

        case ADD_ACTION_TENDENCY:
            return {
                ...state,
                dirty: true,
                actionTendencies: [
                    ...state.actionTendencies.filter(t => t.code !== action.code),
                    {
                        code: action.code,
                        inputs: [],
                        outputs: [],
                    },
                ],
            };
        case DELETE_ACTION_TENDENCY:
            return {
                ...state,
                dirty: true,
                actionTendencies: state.actionTendencies.filter(t => t.code !== action.code),
            };

        case SET_INPUTS:
            return {
                ...state,
                dirty: true,
                actionTendencies: state.actionTendencies
                    .map(t => t.code !== action.code ? t : ({
                        ...t,
                        inputs: action.inputs,
                    })),
            };

        case SET_OUTPUTS:
            return {
                ...state,
                dirty: true,
                actionTendencies: state.actionTendencies
                    .map(t => t.code !== action.code ? t : ({
                        ...t,
                        outputs: action.outputs,
                    })),
            };

        case SAVE_REGULATION__BEGIN:
            return {
                ...state,
                isFetching: true,
                errors: {},
                dirty: false,
            };
        case SAVE_REGULATION__RESOLVE:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                dirty: Object.keys(action.errors).length > 0 ? true : state.dirty,
            };

        // Invalidation
        case SELECT_MODEL__BEGIN:
        case SELECT_VERSION__BEGIN:
            return getInitState();

        // Default
        default:
            return state;
    }
}

export const getRegulationActionTendencies = (state) => state.regulation.actionTendencies;
export const isRegulationDirty = (state) => state.regulation.dirty;
export const isFetchingRegulation = (state) => state.regulation.isFetching;
export const getRegulationErrors = (state) => state.regulation.errors;

export default regulation;
