import { getService } from "../../store/api";
import { SELECT_MODEL__BEGIN, SELECT_MODEL__RESOLVE } from "./types";

const selectModel = (id) => async (dispatch, getState) => {
    dispatch({ type: SELECT_MODEL__BEGIN, id });

    const service = getService(getState());
    let errors = {};
    let model = null;
    try {
        const response = await service.fetchEndpoint(`v1/model/${id}`, 'GET');
        model = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'model');
    }

    dispatch({ type: SELECT_MODEL__RESOLVE, model, errors });
    return model;
};

export default selectModel;
