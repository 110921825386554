import { useEffect, useState } from 'react';
import { Card, Dropdown, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import getMetrics from '../actions/api/getMetrics';
import deselectModel from '../actions/models/deselect';
import selectModel from '../actions/models/select';
import getVersionText from '../services/getVersionText';
import { getModels, getSelectedModel } from '../store/models';
import emotions from '../data/emotions';
import tendencies from '../data/tendencies';
import { getAccount } from '../store/api';

const timeSpanOptions = [
    {
        label: "1 heure",
        duration: 60 * 60,
        intervals: 12,
        intervalFormatter: (date) => `${date.getHours()}h${date.getMinutes().toString().padStart(2, '0')}`,
    },
    {
        label: "1 journée",
        duration: 24 * 60 * 60,
        intervals: 24,
        intervalFormatter: (date) => `${date.getHours()}h${date.getMinutes().toString().padStart(2, '0')}`,
    },
    {
        label: "7 jours",
        duration: 7 * 24 * 60 * 60,
        intervals: 7,
        intervalFormatter: (date) => date.toLocaleDateString(undefined, { weekday: 'long' }),
    },
    {
        label: "31 jours",
        duration: 31 * 24 * 60 * 60,
        intervals: 31,
        intervalFormatter: (date) => date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }),
    },
    {
        label: "1 an",
        duration: 365 * 24 * 60 * 60,
        intervals: 12,
        intervalFormatter: (date) => date.toLocaleDateString(undefined, { month: 'long', day: 'numeric' }),
    },
];

const createTimeSpan = (options) => {
    const current = new Date();
    return {
        options,
        start: new Date(current.getTime() - (options.duration * 1000)),
        end: current,
        interval: options.duration / options.intervals,
    };
};

const viewModes = [
    {
        id: 'total',
        title: 'Nombre d\'appels',
        labelFormatter: (label) => 'Appels',
        backgroundColor: (label) => '#808080',
        category: null,
    },
    {
        id: 'emotions',
        title: 'Appels par émotions',
        labelFormatter: (label) => label === '' ? 'Aucune' : emotions[label]?.label ?? label,
        backgroundColor: (label) => emotions[label]?.color ?? '#808080',
        category: 'emotion',
    },
    {
        id: 'actions',
        title: 'Appels par actions',
        labelFormatter: (label) => label === '' ? 'Aucune' : tendencies[label]?.label ?? label,
        backgroundColor: (label) => tendencies[label]?.color ?? '#808080',
        category: 'action_code',
    },
];

const mapStateToProps = (state) => {
    return {
        account: getAccount(state),
        model: getSelectedModel(state),
        models: getModels(state),
    };
};

const mapDispatchToProps = {
    deselectModel,
    selectModel,
    getMetrics,
};

const graphOptions = {
    animation: {
        duration: 0,
    },
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: "bottom",
            labels: {
                generateLabels: (chart) => {
                    return (chart?.data?.datasets ?? []).map((dataset, datasetIndex) => ({
                        datasetIndex,
                        text: `${dataset.label} (${dataset.data.reduce((a, b) => a + b, 0)})`,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.backgroundColor,
                        hidden: chart.getDatasetMeta(datasetIndex).hidden,
                    }));
                },
            },
        },
        tooltip: {
            callbacks: {
                title: (context) => context.map((c) => {
                    const { dates, timeSpan } = c.dataset;
                    const start = dates[c.dataIndex];
                    const end = dates[c.dataIndex + 1] ?? timeSpan.end;
                    return `${start.toLocaleString()} - ${end.toLocaleString()}`;
                }),
            },
        },
    },
};

const getGraphDataFromMetrics = (timeSpan, viewMode, data) => {
    const dates = [...Array(timeSpan.options.intervals).keys()]
        .map(index => new Date(timeSpan.start.getTime() + (timeSpan.interval * index * 1000)));

    const datasets = {};
    for (const metric of data) {
        const index = parseInt(metric.interval_diff, 10);
        let category = metric[viewMode.category ?? 'sys_account'];
        const count = metric.count;
        datasets[category] = datasets[category] ?? {
            dates,
            timeSpan,
            label: viewMode.labelFormatter(category),
            data: new Array(timeSpan.options.intervals).fill(0),
            backgroundColor: viewMode.backgroundColor(category),
            borderColor: viewMode.backgroundColor(category),
        };
        datasets[category].data[index] = count;
    }

    return {
        labels: dates.map(d => timeSpan.options.intervalFormatter(d)),
        datasets: Object.values(datasets),
    };
};

const ModelMetrics = ({ account, model, models, deselectModel, selectModel, getMetrics }) => {
    const [versionIndex, setVersionIndex] = useState('all');
    const [timeSpan, setTimeSpan] = useState(createTimeSpan(timeSpanOptions[0]));
    const [viewMode, setViewMode] = useState(viewModes[0]);
    const [isFetching, setIsFetching] = useState(false);

    const [graphData, setGraphData] = useState({
        datasets: [],
    });

    useEffect(() => {
        setVersionIndex('all');
    }, [account, model]);

    useEffect(() => {
        let cancel = false;
        setIsFetching(true);
        (async () => {
            const version = versionIndex === 'all' || !model ? null : model.versions[versionIndex];
            const data = await getMetrics(
                model,
                version,
                timeSpan.start,
                timeSpan.end,
                timeSpan.interval,
                viewMode.category,
            );
            if (!cancel) {
                setGraphData(getGraphDataFromMetrics(timeSpan, viewMode, data));
                setIsFetching(false);
            }
        })();
        return () => { cancel = true };
    }, [account, model, versionIndex, timeSpan, viewMode, getMetrics]);

    return (
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center gap-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="link" className="p-1">
                                {model ? model.name : 'Tous les modèles'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={deselectModel}>
                                    Tous les modèles
                                </Dropdown.Item>
                                {
                                    models.map((m) => (
                                        <Dropdown.Item
                                            key={m.id}
                                            onClick={() => selectModel(m.id)}
                                        >
                                            {m.name}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        {!!model &&
                            <Dropdown>
                                <Dropdown.Toggle variant="link" className="p-1">
                                    {versionIndex === 'all' || !model ?
                                        <>Toutes les versions</> :
                                        <>Version {getVersionText(model.versions[versionIndex])}</>
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setVersionIndex('all')}>
                                        Toutes les versions
                                    </Dropdown.Item>
                                    {
                                        Object.entries(model?.versions ?? [])
                                            .map(([index, v]) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => setVersionIndex(index)}
                                                >
                                                    {getVersionText(v)}
                                                </Dropdown.Item>
                                            ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <Dropdown>
                            <Dropdown.Toggle variant="link" className="p-1">
                                {viewMode.title}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    viewModes.map((v) => (
                                        <Dropdown.Item
                                            key={v.id}
                                            onClick={() => setViewMode(v)}
                                        >
                                            {v.title}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        {isFetching &&
                            <Spinner animation="border" variant="primary" size="sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                        <Dropdown>
                            <Dropdown.Toggle variant="link" className="p-1">
                                {timeSpan.options.label}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    timeSpanOptions.map((o) => (
                                        <Dropdown.Item
                                            key={o.duration}
                                            onClick={() => setTimeSpan(createTimeSpan(o))}
                                        >
                                            {o.label}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="vh-50">
                    {graphData.datasets.length > 0 ?
                        <Bar options={graphOptions} data={graphData} /> :
                        <>{isFetching ? '' : 'Aucun résultat trouvé'}</>
                    }
                </div>
            </Card.Body>
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelMetrics);
