import { Dropdown, Nav } from 'react-bootstrap'
import { connect } from 'react-redux';
import selectVersion from '../actions/versions/select';
import getVersionText from '../services/getVersionText';
import { getSelectedVersion, getVersions } from '../store/versions';
import ManageModelVersions from './ManageModelVersions';

const mapStateToProps = (state) => {
    return {
        selectedVersion: getSelectedVersion(state),
        versions: getVersions(state),
    };
};

const mapDispatchToProps = {
    selectVersion,
};

const VersionDropdown = ({ selectedVersion, versions, selectVersion }) => {
    return (
        <Nav role="listbox" className="bg-transparent">
            {selectedVersion &&
                <Dropdown>
                    <Dropdown.Toggle variant="link" className="p-1" id="dropdownMenuButtonSM" aria-label="selected version">
                        Version {getVersionText(selectedVersion)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu aria-labelledby="dropdownMenuButtonSM">
                        {
                            versions.map((version) => (
                                <Dropdown.Item
                                    key={`${version.major}.${version.minor}`}
                                    onClick={() => selectVersion(version.major, version.minor)}
                                >
                                    {getVersionText(version)}
                                </Dropdown.Item>
                            ))
                        }
                        <li><hr className="dropdown-divider" /></li>
                        <ManageModelVersions />
                    </Dropdown.Menu>
                </Dropdown>
            }
        </Nav>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionDropdown);
