import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { REFRESH_VERSION } from "./types";

const refreshVersion = () => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const modelVersion = getSelectedVersion(getState());
    if (!model || !modelVersion) {
        return null;
    }

    const service = getService(getState());
    let errors = {};
    let version = null;
    try {
        const { major, minor } = modelVersion;
        const response = await service.fetchEndpoint(`v1/model/${model.id}/version/${major}.${minor}`, 'GET');
        version = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'version');
    }

    dispatch({ type: REFRESH_VERSION, version, errors });
    return version;
};

export default refreshVersion;
