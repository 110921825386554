import { getService } from "../../store/api";
import { getRegulationActionTendencies } from "../../store/regulation";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { SAVE_REGULATION__BEGIN, SAVE_REGULATION__RESOLVE } from "./types";

const save = () => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return null;
    }

    dispatch({ type: SAVE_REGULATION__BEGIN });

    const body = {
        action_tendency_regulations: getRegulationActionTendencies(getState()).map(t => ({
            code: t.code,
            emotion_inputs: t.inputs,
            emotion_outputs: t.outputs,
        })),
    };

    const service = getService(getState());
    let errors = {};
    let actionTendencies = {};
    try {
        const uri =
            `v1/model/${model.id}/` +
            `version/${version.major}.${version.minor}` +
            `/regulation`
            ;
        const response = await service.fetchEndpoint(uri, 'PUT', body, true);
        actionTendencies = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'action_tendency_regulations');
    }

    const updatedVersion = {
        ...version,
        ...actionTendencies,
    };
    dispatch({ type: SAVE_REGULATION__RESOLVE, version: updatedVersion, errors });
};

export default save;
