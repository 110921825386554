import { useState } from 'react';
import { NavDropdown } from 'react-bootstrap'
import ManageVersionsModal from './ManageVersionsModal'

const ManageModelVersions = () => {
    const [show, setShow] = useState(false);

    const onShow = () => {
        setShow(true);
    };

    const onCancel = () => {
        setShow(false);
    };

    return (
        <>
            <NavDropdown.Item onClick={onShow}>Gérer les versions</NavDropdown.Item>
            {show && <ManageVersionsModal
                show={true}
                title="Gérer les versions"
                onCancel={onCancel}
            />}
        </>
    );
};

export default ManageModelVersions;
