import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import emotions from "../data/emotions";
import tendencies from "../data/tendencies";

const ContextTooltip = ({ context, title, triggerComponent, type }) => {
    return (
        <OverlayTrigger
            trigger="click"
            rootClose
            placement="auto"
            overlay={
                <Popover className="mw-100">
                    <Popover.Header as="h3">{title}</Popover.Header>
                    <Popover.Body>
                        {type === 'emotion' ?
                            (!context?.length ? "Vide" : context.map(e => (
                                <div key={e.emotion}>{emotions[e.emotion].label}: {e.value}</div>
                            ))) :
                            (!context?.length ? "Aucune" : context.map(a => (
                                <div key={a.code}>{tendencies[a.code].label}: {a.score}</div>
                            )))}
                    </Popover.Body>
                </Popover>
            }
        >
            <Button variant="link" className="p-1"  >{triggerComponent}</Button>
        </OverlayTrigger>
    );
};

export default ContextTooltip;
