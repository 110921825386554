import { useState } from "react";
import { Dropdown, Spinner, Nav } from "react-bootstrap";
import ModelPublishModal from "./ModelPublishModal";
import { connect } from "react-redux";
import publishVersion from "../actions/versions/publish";
import fetchVersions from "../actions/versions/fetch";
import selectVersion from "../actions/versions/select";
import slots from "../data/slots";
import { getSelectedVersion } from "../store/versions";

const mapStateToProps = (state) => {
    return {
        selectedVersion: getSelectedVersion(state),
    };
};

const mapDispatchToProps = {
    fetchVersions,
    publishVersion,
    selectVersion,
};

const ModelPublishButton = ({ fetchVersions, publishVersion, selectVersion, selectedVersion }) => {
    const [publishSlot, setPublishSlot] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const onSubmit = () => {
        if (!publishSlot || isFetching) {
            return;
        }
        setIsFetching(true);

        const body = { slot: publishSlot };
        setPublishSlot(null);
        publishVersion(body)
            .then(() => fetchVersions())
            .then(() => selectVersion(selectedVersion?.major ?? 0, selectedVersion?.minor ?? 1))
            .finally(() => setIsFetching(false));
    };

    const onCancelPublish = () => {
        setPublishSlot(null);
    };

    return (
        <Nav role="listbox">
            <Dropdown>
                <Dropdown.Toggle size="sm" variant="success" id="dropdown-item-button" disabled={isFetching}>
                    {isFetching
                        ? (<Spinner animation="border" variant="white" size="sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>)
                        : "Publier"
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Object.entries(slots)
                        .map(([id, { label }]) => (
                            <Dropdown.Item key={id} as="button" onClick={() => setPublishSlot(id)}>{label}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
            <ModelPublishModal show={!!publishSlot} slot={slots[publishSlot]?.label} onConfirm={onSubmit} onCancel={onCancelPublish} />
        </Nav>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelPublishButton);
