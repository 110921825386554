import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FcDownload, FcUpload } from "react-icons/fc";
import { connect } from "react-redux";
import predictVersion from "../actions/versions/predict";
import emotions from "../data/emotions";
import slots from "../data/slots";
import tendencies from "../data/tendencies";
import { getSelectedModel } from "../store/models";
import { getSelectedVersion, getVersionsErrors } from "../store/versions";
import Alert from "./Alert";
import ContextTooltip from "./ContextTooltip";
import ModelTestContext from "./ModelTestContext";

const mapStateToProps = (state) => {
    return {
        model: getSelectedModel(state),
        selectedVersion: getSelectedVersion(state),
        apiErrors: getVersionsErrors(state),
    };
};

const mapDispatchToProps = {
    predictVersion,
};

const ModelTestModal = ({ model, selectedVersion, apiErrors, predictVersion, ...props }) => {
    const [utterances, setUtterances] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const { register, handleSubmit, setFocus, setValue, formState: { errors } } = useForm();
    const bottomRef = useRef(null);

    const [context, setContext] = useState([]);
    const [contextTime, setContextTime] = useState(null);

    useEffect(() => {
        setUtterances([]);
        setContext([]);
    }, [model?.id, selectedVersion?.major, selectedVersion?.minor]);

    useEffect(() => {
        bottomRef?.current?.scrollIntoView?.();
    }, [utterances]);

    const onReset = () => {
        setContext([]);
        setContextTime(null);
    }

    const onSubmit = (formData) => {
        if (!formData.utterance || isFetching) {
            return;
        }
        setIsFetching(true);

        const body = {
            utterance: formData.utterance,
            etag: contextTime,
            context,
        };
        const oldContext = context;
        predictVersion(body).then((predict) => {
            if (predict !== null) {
                setUtterances(utterances => ([...utterances, {
                    ...predict,
                    id: utterances.length + 1,
                    oldContext,
                }]));
                setValue('utterance', '');
                setContext(predict.context);
                setContextTime(predict.etag);
            }
            setIsFetching(false);
        });
    };

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
            fullscreen={true}
            onShow={() => { setFocus('utterance') }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tester la version {selectedVersion && (
                        `${selectedVersion.major}.${selectedVersion.minor}` +
                        (selectedVersion.slot ? ` ${slots[selectedVersion.slot].label}` : '')
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="table-responsive">
                <Row className="flex-wrap-reverse">
                    <Col xl={8}>
                        <table id="utterance-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                            <thead>
                                <tr className="light">
                                    <th>Phrase</th>
                                    <th>Émotion</th>
                                    <th>Score</th>
                                    <th>Action</th>
                                    <th>Contexte</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utterances.map((utterance) => (
                                        <tr key={utterance.id}>
                                            <td className="text-wrap text-break">{utterance.utterance}</td>
                                            <td>{emotions[utterance.emotion]?.label || 'Aucune'}</td>
                                            <td>{utterance.score}</td>
                                            <td>
                                                {
                                                    !utterance['action_tendencies']?.length ?
                                                        <span className="p-1" >Aucune</span> :
                                                        <ContextTooltip
                                                            context={utterance['action_tendencies']}
                                                            title="Actions"
                                                            type="action"
                                                            triggerComponent={tendencies[utterance['action_tendencies'][0].code].label}
                                                        />
                                                }
                                            </td>
                                            <td>
                                                <ContextTooltip
                                                    context={utterance.oldContext}
                                                    title="Envoyé"
                                                    type="emotion"
                                                    triggerComponent={<FcUpload />}
                                                />
                                                <ContextTooltip
                                                    context={utterance.context}
                                                    title="Reçu"
                                                    type="emotion"
                                                    triggerComponent={<FcDownload />}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div ref={bottomRef}></div>
                    </Col>
                    <Col xl={4} className="mb-3">
                        <div className="sticky-xl-top">
                            <ModelTestContext context={context} setContext={setContext} onReset={onReset} />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <form onSubmit={handleSubmit(onSubmit)} className="d-flex align-items-center gap-2 flex-grow-1">
                    <Form.Control {...register("utterance", { required: true })}
                        type="text"
                        placeholder="Entrez la phrase à tester"
                        aria-label="Entrez la phrase à tester"
                        htmlSize={40}
                    />
                    <div className="min-w-25">
                        {isFetching && (
                            <Spinner animation="border" variant="primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        )}
                        <Alert variant="danger m-0 p-2" message={(apiErrors.utterance || errors.utterance?.message) && "Une erreur est survenue"} />
                    </div>
                </form>
                <Button onClick={props.onHide}>Fermer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelTestModal);
