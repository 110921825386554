import getAccessToken from "./getAccessToken";

const fetchAccounts = async () => {
    const response = await fetch(process.env.REACT_APP_MANAGEMENT_GETACCOUNTS, {
        headers: { Authorization: `Bearer ${getAccessToken()}` },
    });
    if (!response.ok) {
        return [];
    }

    return await response.json();
}

export default fetchAccounts;
