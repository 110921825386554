import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Nav, Tab, Alert } from 'react-bootstrap'
import Card from '../HopeUI/components/Card'
import ModelInfo from '../components/ModelInfo'
import ModelUtteranceList from '../components/ModelUtteranceList'
import ModelRegulation from '../components/ModelRegulation'
import VersionDropdown from '../components/VersionDropdown'
import ModelTestButton from '../components/ModelTestButton'
import ModelTrainButton from '../components/ModelTrainButton'
import ModelPublishButton from '../components/ModelPublishButton'
import { connect } from 'react-redux'
import fetchVersions from '../actions/versions/fetch'
import selectModel from '../actions/models/select'
import { getModelsErrors, getSelectedModel } from '../store/models'
import { selectLastVersion } from '../actions/versions/select'
import ModelTrainList from '../components/ModelTrainList'

const mapStateToProps = (state) => {
    return {
        errors: getModelsErrors(state),
        model: getSelectedModel(state),
    };
};

const mapDispatchToProps = {
    fetchVersions,
    selectModel,
    selectLastVersion,
};

const Model = ({ errors, model, fetchVersions, selectModel, selectLastVersion }) => {
    let { id } = useParams();
    const [key, setKey] = useState('model-information');

    useEffect(() => {
        selectModel(id)
            .then(() => fetchVersions())
            .then(() => selectLastVersion());
    }, [id, selectModel, fetchVersions, selectLastVersion]);

    if (errors.model) {
        return (
            <Alert variant="danger d-flex align-items-center mt-1" role="alert">
                {errors.model}
            </Alert>
        );
    }
    if (!model) {
        return <></>;
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <div className="header-title">
                    <h4 className="card-title my-3">{model.name}</h4>
                </div>
                <div className="d-flex flex-grow-1 justify-content-end align-items-center gap-2">
                    <div className="d-flex">
                        <VersionDropdown />
                    </div>
                    <ModelTrainButton />
                    <ModelTestButton />
                    <ModelPublishButton />
                </div>
            </div>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <Nav variant="tabs" className="mb-3 flex-column flex-sm-row text-center text-sm-start" id="nav-tab" role="tablist">
                    <Nav.Link
                        className="border-1 px-4"
                        eventKey="model-information"
                        id="model-information-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#model-information"
                        type="button"
                        role="tab"
                        aria-controls="model-information"
                        aria-selected="true"
                    >
                        Informations
                    </Nav.Link>
                    <Nav.Link
                        className="border-1 px-4"
                        eventKey="model-trains"
                        id="model-trains-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#model-trains"
                        type="button"
                        role="tab"
                        aria-controls="model-trains"
                        aria-selected="true"
                    >
                        Entrainements
                    </Nav.Link>
                    <Nav.Link
                        className="border-1 px-4"
                        eventKey="model-utterances"
                        id="model-utterances-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#model-utterances"
                        type="button"
                        role="tab"
                        aria-controls="model-utterances"
                        aria-selected="false"
                    >
                        Phrases de formation
                    </Nav.Link>
                    <Nav.Link
                        className="border-1 px-4"
                        eventKey="model-regulation"
                        id="model-regulation-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#model-regulation"
                        type="button"
                        role="tab"
                        aria-controls="model-regulation"
                        aria-selected="false"
                    >
                        Régulation
                    </Nav.Link>
                </Nav>
                <Card>
                    <Card.Body className="px-0">
                        <Tab.Content>
                            <Tab.Pane className="fade show" id="model-information" eventKey="model-information" role="tabpanel" aria-labelledby="model-information-tab">
                                <ModelInfo />
                            </Tab.Pane>
                            <Tab.Pane className="fade show" id="model-trains" eventKey="model-trains" role="tabpanel" aria-labelledby="model-trains-tab">
                                <ModelTrainList />
                            </Tab.Pane>
                            <Tab.Pane className="fade show" id="model-utterances" eventKey="model-utterances" role="tabpanel" aria-labelledby="model-utterances-tab">
                                <ModelUtteranceList />
                            </Tab.Pane>
                            <Tab.Pane className="fade show" id="model-regulation" eventKey="model-regulation" role="tabpanel" aria-labelledby="model-regulation-tab">
                                <ModelRegulation />
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                </Card>
            </Tab.Container>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Model);
