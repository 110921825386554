import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { ADD_VERSION__BEGIN, ADD_VERSION__RESOLVE, SORT_VERSIONS } from "./types";

const addVersion = (version, baseVersion) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    if (!model) {
        return null;
    }

    dispatch({ type: ADD_VERSION__BEGIN, version });

    const service = getService(getState());
    let errors = {};
    let responseVersion = null;
    try {
        const body = { version };
        if (baseVersion) {
            body['base_version'] = {
                major: baseVersion.major,
                minor: baseVersion.minor,
            };
        }
        const response = await service.fetchEndpoint(`v1/model/${model.id}/version/`, 'POST', body, true);
        responseVersion = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'version');
    }

    dispatch({ type: ADD_VERSION__RESOLVE, version: responseVersion, errors });
    dispatch({ type: SORT_VERSIONS });
    return responseVersion;
};

export default addVersion;
