import { SET_ACCOUNT } from "../actions/api/types";
import { SELECT_MODEL__RESOLVE } from "../actions/models/types";
import { SAVE_REGULATION__RESOLVE } from "../actions/regulation/types";
import { ADD_VERSION__BEGIN, ADD_VERSION__RESOLVE, DELETE_VERSION__BEGIN, DELETE_VERSION__RESOLVE, FETCH_VERSIONS__BEGIN, FETCH_VERSIONS__RESOLVE, PREDICT_VERSION__BEGIN, PREDICT_VERSION__RESOLVE, TRAIN_VERSION__BEGIN, TRAIN_VERSION__RESOLVE, PUBLISH_VERSION__BEGIN, PUBLISH_VERSION__RESOLVE, SELECT_VERSION__BEGIN, SELECT_VERSION__RESOLVE, SORT_VERSIONS, REFRESH_VERSION } from "../actions/versions/types";

const getInitState = () => ({
    versions: [],
    isFetching: false,
    errors: {},
    selected: null,
});

const versions = (state = getInitState(), action) => {
    switch (action.type) {
        // Fetch
        case FETCH_VERSIONS__BEGIN:
            return {
                ...state,
                versions: [],
                isFetching: true,
                errors: {},
            };
        case FETCH_VERSIONS__RESOLVE:
            return {
                ...state,
                versions: action.versions,
                isFetching: false,
                errors: action.errors,
            };

        // Add
        case ADD_VERSION__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case ADD_VERSION__RESOLVE:
            return {
                ...state,
                versions: action.version === null ? state.versions : [...state.versions, action.version],
                errors: action.errors,
            };

        // Delete
        case DELETE_VERSION__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case DELETE_VERSION__RESOLVE:
            return {
                ...state,
                versions: state.versions.filter(v => (v.major !== action.version?.major || v.minor !== action.version?.minor)),
            };

        // Predict
        case PREDICT_VERSION__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case PREDICT_VERSION__RESOLVE:
            return {
                ...state,
                errors: action.errors,
            };

        // Train
        case TRAIN_VERSION__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case TRAIN_VERSION__RESOLVE:
            return {
                ...state,
                errors: action.errors,
            };

        // Publish
        case PUBLISH_VERSION__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case PUBLISH_VERSION__RESOLVE:
            return {
                ...state,
                errors: action.errors,
            };

        // Select
        case SELECT_VERSION__BEGIN:
            return {
                ...state,
                selected: null,
                errors: {},
            };
        case SELECT_VERSION__RESOLVE:
            return {
                ...state,
                selected: action.version,
                versions: state.versions.map(v => v.major !== action.version?.major || v.minor !== action.version?.minor ? v : action.version),
                errors: action.errors,
            };
        case REFRESH_VERSION:
            return {
                ...state,
                selected:
                    (state.selected?.major !== action.version?.major || state.selected?.minor !== action.version?.minor) ?
                        state.selected : action.version,
                versions: state.versions.map(v => v.major !== action.version?.major || v.minor !== action.version?.minor ? v : action.version),
                errors: action.errors,
            };

        // Sort
        case SORT_VERSIONS:
            return {
                ...state,
                versions: [...state.versions].sort((a, b) => (a.major === b.major ? a.minor - b.minor : a.major - b.major)),
            };

        // Invalidation
        case SELECT_MODEL__RESOLVE:
        case SET_ACCOUNT:
            return getInitState();

        // Regulation
        case SAVE_REGULATION__RESOLVE:
            return Object.keys(action.errors).length > 0 ? state : {
                ...state,
                selected: action.version,
                versions: state.versions.map(v => v.major !== action.version?.major || v.minor !== action.version?.minor ? v : action.version),
            };

        // Default
        default:
            return state;
    }
}

export const getVersions = (state) => state.versions.versions;
export const isFetchingVersions = (state) => state.versions.isFetching;
export const getVersionsErrors = (state) => state.versions.errors;
export const getSelectedVersion = (state) => state.versions.selected;

export default versions;
