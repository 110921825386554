export const tendencies = {
    "TA01": {label: "Tout casser", color: '#008FFB'},
    "TA02": {label: "Retirer l'obstacle", color: '#00E396'},
    "TA03": {label: "Se punir", color: '#FEB019'},
    "TA03.1": {label: "Se reprocher", color: '#FF9800'},
    "TA04": {label: "Attaquer l'autre", color: '#FF4560'},
    "TA05": {label: "Insulter l'autre", color: '#775DD0'},
    "TA06": {label: "Blesser l'autre", color: '#3f51b5'},
    "TA07": {label: "Éviter le problème", color: '#03a9f4'},
    "TA08": {label: "S'enfuir de la situation", color: '#4caf50'},
    "TA09": {label: "Se protéger", color: '#f9ce1d'},
    "TA10": {label: "Pleurer", color: '#FF9800'},
    "TA11": {label: "Demander de l'aide", color: '#5C4742'},
    "TA12": {label: "Corriger l'erreur", color: '#2b908f'},
    "TA13": {label: "Minimiser", color: '#f9a3a4'},
    "TA14": {label: "Subir les conséquences de son erreur", color: '#90ee7e'},
    "TA15": {label: "S'éloigner de l'autre", color: '#69d2e7'},
    "TA15.2": {label: "Eviter l'autre", color: '#4caf50'},
    "TA16": {label: "Sauter de joie", color: '#fa4443'},
    "TA17": {label: "Partager sa bonne humeur", color: '#008FFB'},
    "TA18": {label: "Continuer dans le même plan", color: '#00E396'},
    "TA19": {label: "Faire savoir autour de soi", color: '#FEB019'},
    "TA20": {label: "S'afficher", color: '#FF4560'},
    "TA21": {label: "Chercher la reconnaissance", color: '#775DD0'},
    "TA22": {label: "Se rapprocher de l'autre", color: '#3f51b5'},
    "TA23": {label: "Chercher le contact de l'autre", color: '#03a9f4'},
    "TA24": {label: "Rejeter la situation", color: '#4caf50'},
    "TA25": {label: "Se soumettre", color: '#f9ce1d'},
    "TA26": {label: "Se désister", color: '#FF9800'},
    "TA27": {label: "Se cacher", color: '#5C4742'},
    "TA28": {label: "Repousser l'autre", color: '#2b908f'},
    "TA28.1": {label: "Critiquer l’autre ", color: '#69d2e7'},
    "TA29": {label: "Renoncer", color: '#f9a3a4'},
    "TA30": {label: "Se détendre", color: '#90ee7e'},
};

export const compareTendencies = (a, b) => {
    const c = new Intl.Collator();
    return c.compare(tendencies[a]?.label, tendencies[b]?.label);
};

export default tendencies;
