import slots from '../data/slots';

const getVersionText = (version) => {
    return (
        <>
            {`${version.major}.${version.minor}`}
            {version.slot && <>{' '}<b>{slots[version.slot].label}</b></>}
        </>
    );
};

export default getVersionText;
