import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useHistory, useParams } from "react-router-dom";
import ModelForm from '../components/ModelForm'
import { connect } from 'react-redux';
import { getModels, getModelsErrors, getSelectedModel } from '../store/models';
import selectModel from '../actions/models/select';
import fetchModels from '../actions/models/fetch';
import editModel from '../actions/models/edit';

const mapStateToProps = (state) => {
   return {
      baseModels: getModels(state),
      errors: getModelsErrors(state),
      model: getSelectedModel(state),
   };
};

const mapDispatchToProps = {
   editModel,
   fetchModels,
   selectModel,
};

const ModelEdit = ({ baseModels, errors, model, editModel, fetchModels, selectModel }) => {
   const { id } = useParams();
   const history = useHistory();

   useEffect(() => {
      fetchModels();
   }, [fetchModels]);

   useEffect(() => {
      selectModel(id);
   }, [id, selectModel]);

   const onSubmit = formData => {
      const body = {
         name: formData.name,
         base_model: formData.base_model !== '0' ? formData.base_model : null,
      };
      editModel(id, body).then((result) => {
         if (result !== null) {
            history.push('../../models');
         }
      });
   };

   const onCancel = () => {
      history.push('../../models');
   }

   return (
      <Row>
         <Col xl="12">
            {(!!model || errors.model) && (
               <ModelForm
                  title="Modifier un modèle"
                  submitText="Modifier le modèle"
                  model={model}
                  apiErrors={errors}
                  baseModels={baseModels}
                  onSubmit={onSubmit}
                  onCancel={onCancel}
               />
            )}
         </Col>
      </Row>
   )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelEdit);
