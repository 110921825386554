import { SET_ACCOUNT } from "../actions/api/types";
import { SELECT_MODEL__RESOLVE } from "../actions/models/types";
import { ADD_UTTERANCE__BEGIN, ADD_UTTERANCE__RESOLVE, DELETE_UTTERANCE__BEGIN, DELETE_UTTERANCE__RESOLVE, EDIT_UTTERANCE__BEGIN, EDIT_UTTERANCE__RESOLVE, FETCH_UTTERANCES__BEGIN, FETCH_UTTERANCES__RESOLVE, INVALIDATE_UTTERANCE_SCORE, SCORE_UTTERANCE__BEGIN, SCORE_UTTERANCE__RESOLVE } from "../actions/utterances/types";
import { SELECT_VERSION__RESOLVE } from "../actions/versions/types";

const getInitState = () => ({
    utterances: [],
    isFetching: false,
    errors: {},
    offset: 0,
    totalCount: 0,
});

const utterances = (state = getInitState(), action) => {
    switch (action.type) {
        // Fetch
        case FETCH_UTTERANCES__BEGIN:
            return {
                ...state,
                utterances: [],
                isFetching: true,
                offset: action.offset,
                totalCount: 0,
                errors: {},
            };
        case FETCH_UTTERANCES__RESOLVE:
            return {
                ...state,
                utterances: action.utterances,
                isFetching: false,
                totalCount: action.totalCount,
                errors: action.errors,
            };

        // Delete
        case DELETE_UTTERANCE__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case DELETE_UTTERANCE__RESOLVE:
            return {
                ...state,
                utterances: state.utterances.filter(u => u.id !== action.utterance?.id),
            };

        // Add
        case ADD_UTTERANCE__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case ADD_UTTERANCE__RESOLVE:
            return {
                ...state,
                utterances: action.utterance === null ? state.utterances : [...state.utterances, action.utterance],
                errors: action.errors,
            };

        // Edit
        case EDIT_UTTERANCE__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case EDIT_UTTERANCE__RESOLVE:
            return {
                ...state,
                utterances: state.utterances.map(u => u.id !== action.utterance?.id ? u : {
                    ...action.utterance,
                    scoringFailed: false,
                }),
                errors: action.errors,
            };

        // Score
        case SCORE_UTTERANCE__BEGIN:
            return {
                ...state,
                utterances: state.utterances.map(u => u.id !== action.utterance.id ? u : {
                    ...u,
                    score: null,
                    scoredEmotion: null,
                    isScoring: true,
                }),
            };
        case SCORE_UTTERANCE__RESOLVE:
            return {
                ...state,
                utterances: state.utterances.map(u => u.id !== action.utterance.id ? u : {
                    ...u,
                    score: action.predict?.score ?? null,
                    scoredEmotion: action.predict?.emotion ?? null,
                    isScoring: false,
                    scoringFailed: action.predict === null,
                }),
            };

        // Invalidation
        case SELECT_MODEL__RESOLVE:
        case SELECT_VERSION__RESOLVE:
        case SET_ACCOUNT:
            return getInitState();

        case INVALIDATE_UTTERANCE_SCORE:
            return {
                ...state,
                utterances: state.utterances.map(u => u.id !== action.utterance.id ? u : {
                    ...u,
                    score: null,
                }),
            };

        // Default
        default:
            return state;
    }
}

export const getUtterances = (state) => state.utterances.utterances;
export const isFetchingUtterances = (state) => state.utterances.isFetching;
export const getUtterancesOffset = (state) => state.utterances.offset;
export const getUtterancesErrors = (state) => state.utterances.errors;
export const getUtterancesTotalCount = (state) => state.utterances.totalCount;

export default utterances;
