import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { getSelectedVersion, getVersions } from '../store/versions';
import selectVersion, { selectLastVersion } from '../actions/versions/select';
import deleteVersion from '../actions/versions/delete';
import addVersion from '../actions/versions/add';
import { connect } from 'react-redux';
import slots from '../data/slots';

const mapStateToProps = (state) => {
	return {
		selectedVersion: getSelectedVersion(state),
		versions: getVersions(state),
	};
};

const mapDispatchToProps = {
	addVersion,
	deleteVersion,
	selectVersion,
	selectLastVersion,
};

const ManageVersionsModal = ({
	show,
	title,
	onCancel,
	selectedVersion,
	versions,
	addVersion,
	deleteVersion,
	selectVersion,
	selectLastVersion,
}) => {
	const versionRef = useRef(null);
	const [managedVersionIndex, setManagedVersionIndex] = useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const managedVersion = versions[managedVersionIndex] ?? null;
	const lastVersion = versions[versions.length - 1];

	useEffect(() => {
		if (managedVersionIndex === null) {
			const selectedVersionIndex = versions.findIndex(
				(v) => v.major === selectedVersion?.major && v.minor === selectedVersion?.minor
			);
			setManagedVersionIndex(Math.max(0, selectedVersionIndex));
		}
	}, [managedVersionIndex, selectedVersion, versions]);

	const onDelete = () => {
		deleteVersion(managedVersion).then(() => {
			if (managedVersion.major === selectedVersion?.major && managedVersion.minor === selectedVersion?.minor) {
				selectLastVersion().then(() => setManagedVersionIndex(null));
			} else {
				setManagedVersionIndex(0);
			}
		});
	};

	const onCreate = (event) => {
		event.preventDefault();
		if (isFetching) {
			return;
		}

		const regexp = /^(?<major>\d+)(\.(?<minor>\d+)?)?$/;
		const match = versionRef?.current?.value?.match?.(regexp);
		if (!match) {
			return;
		}

		const body = {
			major: parseInt(match.groups.major, 10),
			minor: parseInt(match.groups.minor ?? 0, 10),
		};
		setIsFetching(true);
		addVersion(body, managedVersion)
			.then((result) => {
				if (result !== null) {
					onCancel();
					selectVersion(result.major, result.minor);
				}
			})
			.finally(() => setIsFetching(false));
	};

	return (
		<Modal show={show} onHide={onCancel}
			size="lg"
			centered
			fullscreen="md-down"
		>
			<Modal.Header closeButton>
				<Modal.Title as="h5">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group className="mb-3" controlId="version-select">
					<Form.Label>Sélectionnez une version</Form.Label>
					<Form.Select
						value={managedVersionIndex || 0}
						onChange={(e) => setManagedVersionIndex(e.target.value)}
					>
						{
							versions?.map((version, index) => (
								<option key={index} value={index}>
									{version.major}.{version.minor}
									{version.slot && ` ${slots[version.slot].label}`}
								</option>
							))
						}
					</Form.Select>
				</Form.Group>
				{managedVersion && versions.length > 1 &&
					<Form.Group className="mb-3">
						<Button type="button" variant="danger" onClick={onDelete}>Supprimer</Button>
					</Form.Group>
				}
				{managedVersion &&
					<Form.Group className="mb-3">
						<Form.Label>Nouvelle version</Form.Label>
						<form className="d-flex" onSubmit={onCreate}>
							<Form.Control ref={versionRef} defaultValue={`${lastVersion?.major ?? 0}.${(lastVersion?.minor ?? 0) + 1}`} />
							<Button type="submit" variant="primary" className="ms-2">
								{isFetching
									? <Spinner animation="border" variant="white" size="sm" role="status">
										<span className="visually-hidden">Loading...</span>
									</Spinner>
									: "Dupliquer"}
							</Button>
						</form>
					</Form.Group>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={onCancel}>Fermer</Button>
			</Modal.Footer>
		</Modal >
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageVersionsModal);
