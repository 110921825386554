import { Button, Modal } from 'react-bootstrap'

const ModelPublishModal = ({ show, slot, onConfirm, onCancel }) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Publication d'une version</Modal.Title>
            </Modal.Header>
            {slot && <Modal.Body>
                Confirmez-vous la publication en <b>{slot}</b>?
            </Modal.Body>}
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Annuler</Button>
                <Button variant="primary" onClick={onConfirm}>Confirmer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModelPublishModal;
