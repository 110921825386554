import React, {useEffect} from 'react'
import {bindActionCreators} from "redux"

// store
import {NavbarstyleAction, getDirMode, SchemeDirAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
        },
        dispatch
    )
})


const SubHeader = (props) => {

    useEffect(() => {
        // navbarstylemode
       const navbarstyleMode = sessionStorage.getItem('Navbarstyle-mode');
       props.NavbarstyleAction(navbarstyleMode);
 })
    return (
        <>
            <div style={{marginBottom: "66px"}}></div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader)
