import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { DELETE_UTTERANCE__BEGIN, DELETE_UTTERANCE__RESOLVE } from "./types";

const deleteUtterance = utterance => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return;
    }

    dispatch({ type: DELETE_UTTERANCE__BEGIN, utterance });

    const service = getService(getState());
    const uri =
        `v1/model/${model.id}/` +
        `version/${version.major}.${version.minor}` +
        `/utterance/${utterance.id}`
        ;
    await service.fetchEndpoint(uri, 'DELETE');

    dispatch({ type: DELETE_UTTERANCE__RESOLVE, utterance });
};

export default deleteUtterance;
