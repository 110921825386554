import Card from '../HopeUI/components/Card'
import { Form, Button } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import Alert from './Alert';

const ModelForm = ({ title, submitText, baseModels, model, apiErrors, onSubmit, onCancel }) => {
	const { register, handleSubmit, formState: { errors } } = useForm();

	return (
		<>
			<h4 className="my-3">{title}</h4>
			<Card>
				<Card.Body>
					<Alert message={apiErrors.model} />
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<Form.Group className="col-sm-12 form-group">
								<Form.Label htmlFor="model-name">Nom du modèle</Form.Label>
								<Form.Control {...register("name", { required: true })}
									type="text"
									id="model-name"
									placeholder="Nom du modèle"
									defaultValue={model?.name}
								/>
								<Alert message={apiErrors.name} />
								<Alert message={errors.name?.message} />
							</Form.Group>
							<Form.Group className="col-sm-12 form-group">
								<Form.Label htmlFor="model-base">Modèle parent</Form.Label>
								<Form.Select {...register("base_model")}
									id="model-base"
									data-style="py-0"
									defaultValue={model?.base_model ? model.base_model.id : 0}
								>
									<option value={0}>Aucun</option>
									{
										baseModels.map(baseModel => (
											<option key={baseModel.id} value={baseModel.id}>{baseModel.name}</option>
										))
									}
								</Form.Select>
								<Alert message={apiErrors.base_model} />
								<Alert message={errors.base_model?.message} />
							</Form.Group>
						</div>
						<Button type="button" variant="danger me-3" onClick={onCancel}>Annuler</Button>
						<Button type="submit" variant="primary me-3">{submitText}</Button>
					</form>
				</Card.Body>
			</Card>
		</>
	);
};

export default ModelForm;
