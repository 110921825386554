import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getVersions } from "../../store/versions";
import { INIT_REGULATION } from "../regulation/types";
import { SELECT_VERSION__BEGIN, SELECT_VERSION__RESOLVE } from "./types";

const selectVersion = (major, minor) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    if (!model) {
        return null;
    }

    dispatch({ type: SELECT_VERSION__BEGIN, major, minor });

    const service = getService(getState());
    let errors = {};
    let version = null;
    try {
        const response = await service.fetchEndpoint(`v1/model/${model.id}/version/${major}.${minor}`, 'GET');
        version = await response.json();
        dispatch({ type: INIT_REGULATION, actionTendencies: version['action_tendency_regulations'] });
    } catch (response) {
        errors = await service.getErrors(response, 'version');
    }

    dispatch({ type: SELECT_VERSION__RESOLVE, version, errors });
    return version;
};

export const selectLastVersion = () => async (dispatch, getState) => {
    const versions = getVersions(getState());
    if (!versions.length) {
        return;
    }
    const lastVersion = versions[versions.length - 1];
    return await selectVersion(lastVersion.major, lastVersion.minor)(dispatch, getState);
};

export default selectVersion;
