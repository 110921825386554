import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import deleteModel from '../actions/models/delete';
import slots from '../data/slots';
import { getSelectedModel } from '../store/models';
import { getSelectedVersion } from '../store/versions';
import DeleteModal from './DeleteModal';

const getTrainingString = (version) => {
    if (version.training) {
        return 'En cours';
    }

    if (version.needs_train) {
        return 'Obsolète';
    }

    if (version.needs_train_update) {
        return 'Mise à jour disponible';
    }

    return 'À jour';
}

const mapStateToProps = (state) => {
    return {
        model: getSelectedModel(state),
        selectedVersion: getSelectedVersion(state),
    };
};

const mapDispatchToProps = {
    deleteModel,
};

const ModelInfo = ({ model, deleteModel, selectedVersion }) => {
    const history = useHistory();
    const [deletingModel, setDeletingModel] = useState(false);

    const onConfirmDelete = () => {
        deleteModel(model);
        setDeletingModel(false);
        history.push('/dashboard/app/models');
    };

    const onCancelDelete = () => {
        setDeletingModel(false);
    };

    return (
        <div className="px-4 d-flex flex-column">
            <span className="mb-2"><b>Identifiant</b> : {model.id}</span>
            <span className="mb-2"><b>Créé le</b> : {(new Date(model.created_at)).toLocaleString()}</span>
            {model.base_model &&
                <span className="mb-2">
                    <b>Modèle parent</b>{' : '}
                    <Link to={`/dashboard/app/models/${model.base_model.id}`}>
                        {model.base_model.name}
                    </Link>
                    {selectedVersion?.base_training ?
                        ' (Entrainement en cours)' :
                        (selectedVersion?.base_needs_train ? ' (Entrainement nécessaire)' : '')}
                </span>
            }
            {selectedVersion && (
                <>
                    <h5 className="my-3">
                        Version {selectedVersion.major}.{selectedVersion.minor}
                    </h5>
                    {selectedVersion.modified_at &&
                        <span className="mb-2">
                            <b>Modifié le</b> : {(new Date(selectedVersion.modified_at)).toLocaleString()}
                        </span>
                    }
                    <span className="mb-2">
                        <b>Slot</b> : {!!selectedVersion.slot ? slots[selectedVersion.slot].label : "Aucun"}
                    </span>
                    <span className="mb-2">
                        <b>Entrainement</b> : {getTrainingString(selectedVersion)}
                    </span>
                </>
            )}
            <div className="mt-4">
                <Button type="button" variant="primary me-3" onClick={() => history.push(`${model.id}/edit`)}>Modifier</Button>
                <Button type="button" variant="danger me-3" onClick={() => setDeletingModel(true)}>Supprimer</Button>
            </div>
            <DeleteModal show={deletingModel} onConfirm={onConfirmDelete} onCancel={onCancelDelete} />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelInfo);
