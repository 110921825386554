import { getRegulationActionTendencies } from "../../store/regulation";
import { SET_INPUTS } from "./types";

const insertPoint = (code, emotion, x, y) => async (dispatch, getState) => {
    const actionTendency = getRegulationActionTendencies(getState()).find(t => t.code === code);

    x = Math.max(0, Math.min(100, x));
    y = Math.max(0, Math.min(100, y));

    const inputs = actionTendency.inputs.map((input) => {
        if (input.emotion !== emotion) return input;
        let points = [...input.points];
        for (let i = 0;; ++i) {
            if (i === points.length || x < points[i].x) {
                points.splice(i, 0, {x, y});
                break;
            }
        }
        return { ...input, points };
    });

    dispatch({ type: SET_INPUTS, code, inputs });
};

export default insertPoint;
