import { SET_ACCOUNT } from "../actions/api/types";
import { ADD_MODEL__BEGIN, ADD_MODEL__RESOLVE, DELETE_MODEL__BEGIN, DELETE_MODEL__RESOLVE, DESELECT_MODEL, EDIT_MODEL__BEGIN, EDIT_MODEL__RESOLVE, FETCH_MODELS__BEGIN, FETCH_MODELS__RESOLVE, SELECT_MODEL__BEGIN, SELECT_MODEL__RESOLVE } from "../actions/models/types";

const getInitState = () => ({
    models: [],
    isFetching: false,
    errors: {},
    offset: 0,
    totalCount: 0,
    selected: null,
});

const models = (state = getInitState(), action) => {
    switch (action.type) {
        // Fetch
        case FETCH_MODELS__BEGIN:
            return {
                ...state,
                models: [],
                isFetching: true,
                offset: action.offset,
                totalCount: 0,
                errors: {},
            };
        case FETCH_MODELS__RESOLVE:
            return {
                ...state,
                models: action.models,
                isFetching: false,
                totalCount: action.totalCount,
                errors: action.errors,
            };

        // Delete
        case DELETE_MODEL__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case DELETE_MODEL__RESOLVE:
            return {
                ...state,
                models: state.models.filter(m => m.id !== action.model?.id),
            };

        // Add
        case ADD_MODEL__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case ADD_MODEL__RESOLVE:
            return {
                ...state,
                models: action.model === null ? state.models : [...state.models, action.model],
                errors: action.errors,
            };

        // Select
        case SELECT_MODEL__BEGIN:
            return {
                ...state,
                selected: null,
                errors: {},
            };
        case SELECT_MODEL__RESOLVE:
            return {
                ...state,
                selected: action.model,
                models: state.models.map(m => m.id !== action.model?.id ? m : action.model),
                errors: action.errors,
            };
        case DESELECT_MODEL:
            return {
                ...state,
                selected: null,
            };

        // Edit
        case EDIT_MODEL__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case EDIT_MODEL__RESOLVE:
            return {
                ...state,
                models: state.models.map(m => m.id !== action.model?.id ? m : action.model),
                errors: action.errors,
            };

        // Invalidation
        case SET_ACCOUNT:
            return getInitState();

        // Default
        default:
            return state;
    }
}

export const getModels = (state) => state.models.models;
export const isFetchingModels = (state) => state.models.isFetching;
export const getModelsOffset = (state) => state.models.offset;
export const getModelsErrors = (state) => state.models.errors;
export const getModelsTotalCount = (state) => state.models.totalCount;
export const getSelectedModel = (state) => state.models.selected;

export default models;
