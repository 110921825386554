import { SET_ACCOUNT } from "../actions/api/types";
import { ABORT_NOTIFICATIONS, FETCH_NOTIFICATIONS__BEGIN, FETCH_NOTIFICATIONS__RESOLVE, SEEN_NOTIFICATION__BEGIN, SEEN_NOTIFICATION__RESOLVE, SUBSCRIBE_NOTIFICATIONS } from "../actions/notifications/types";

const getInitState = () => ({
    notifications: [],
    isFetching: false,
    errors: {},
});

const notifications = (state = getInitState(), action) => {
    switch (action.type) {
        // Fetch
        case FETCH_NOTIFICATIONS__BEGIN:
            return {
                ...state,
                notifications: [],
                isFetching: true,
                errors: {},
            };
        case FETCH_NOTIFICATIONS__RESOLVE:
            return {
                ...state,
                notifications: action.notifications,
                isFetching: false,
                errors: action.errors,
            };

        // Edit
        case SEEN_NOTIFICATION__BEGIN:
            return {
                ...state,
                errors: {},
            };
        case SEEN_NOTIFICATION__RESOLVE:
            return {
                ...state,
                notifications: state.notifications.map(n => n.id !== action.notification?.id ? n : { ...action.notification, seen: true }),
                errors: action.errors,
            };

        // SSE
        case SUBSCRIBE_NOTIFICATIONS:
            return {
                ...state,
                controller: action.controller,
            };
        case ABORT_NOTIFICATIONS:
            return {
                ...state,
                controller: null,
            };

        // Invalidation
        case SET_ACCOUNT:
            return {
                ...getInitState(),
                controller: state.controller,
            };

        // Default
        default:
            return state;
    }
}

export const getNotifications = (state) => state.notifications.notifications;
export const isFetchingNotifications = (state) => state.notifications.isFetching;
export const getNotificationsErrors = (state) => state.notifications.errors;
export const getNotificationsController = (state) => state.notifications.controller;

export default notifications;
