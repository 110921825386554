import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { EDIT_UTTERANCE__BEGIN, EDIT_UTTERANCE__RESOLVE } from "./types";

const editUtterance = (id, utterance) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return null;
    }

    dispatch({ type: EDIT_UTTERANCE__BEGIN, id, utterance });

    const service = getService(getState());
    let errors = {};
    let responseUtterance = null;
    try {
        const uri =
            `v1/model/${model.id}/` +
            `version/${version.major}.${version.minor}` +
            `/utterance/${id}`
            ;
        const response = await service.fetchEndpoint(uri, 'PUT', utterance, true);
        responseUtterance = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'utterance');
    }

    dispatch({ type: EDIT_UTTERANCE__RESOLVE, utterance: responseUtterance, errors });
    return responseUtterance;
};

export default editUtterance;
