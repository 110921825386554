import { getRegulationActionTendencies } from "../../store/regulation";
import { SET_OUTPUTS } from "./types";

const setOutput = (code, emotion, value) => async (dispatch, getState) => {
    const actionTendency = getRegulationActionTendencies(getState()).find(t => t.code === code);

    const found = actionTendency.outputs.find(o => o.emotion === emotion);
    const newOutput = {
        emotion,
        value,
    };

    const outputs =
        found === undefined ?
            [...actionTendency.outputs, newOutput] :
            actionTendency.outputs.map(o => o !== found ? o : newOutput);

    dispatch({ type: SET_OUTPUTS, code, outputs });
};

export default setOutput;
