const KEY = `oidc.davi.account:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;

export const getAccount = () => {
    return localStorage.getItem(KEY);
};

export const setAccount = (account) => {
    localStorage.setItem(KEY, account);
    return account;
};

export const clearAccount = () => {
    localStorage.removeItem(KEY);
};

export default getAccount;
