import { useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import addActionTendency from "../actions/regulation/addActionTendency";
import resetRegulation from "../actions/regulation/reset";
import saveRegulation from "../actions/regulation/save";
import tendencies, { compareTendencies } from "../data/tendencies";
import { getRegulationActionTendencies, getRegulationErrors, isFetchingRegulation, isRegulationDirty } from "../store/regulation";
import RegulationCard from "./RegulationCard";

const mapStateToProps = (state) => {
    return {
        actionTendencies: getRegulationActionTendencies(state),
        errors: getRegulationErrors(state),
        isDirty: isRegulationDirty(state),
        isFetching: isFetchingRegulation(state),
    };
};

const mapDispatchToProps = {
    addActionTendency,
    resetRegulation,
    saveRegulation,
};

const ModelRegulation = ({ actionTendencies, errors, isDirty, isFetching, addActionTendency, resetRegulation, saveRegulation }) => {
    const { register, reset, getValues, handleSubmit } = useForm();

    const onSubmit = (formData) => {
        if (!formData?.tendency) {
            return;
        }
        addActionTendency(formData.tendency);
    };

    useEffect(() => {
        if (
            getValues('tendency') === '' ||
            actionTendencies.find(t => t.code === getValues('tendency')) !== undefined
        ) {
            reset();
        }
    }, [actionTendencies, getValues, reset]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex my-3 px-4 flex-wrap gap-2 justify-content-center">
                <div className="flex-grow-1">
                    <Form.Select {...register("tendency")}>
                        {
                            Object.entries(tendencies)
                                .filter(([code]) => actionTendencies.find(t => t.code === code) === undefined)
                                .sort(([codeA], [codeB]) => compareTendencies(codeA, codeB))
                                .map(([code, { label }]) => <option key={code} value={code}>{label}</option>)
                        }
                    </Form.Select>
                </div>
                <div className="d-flex flex-wrap gap-2 justify-content-center">
                    <Button type="submit" variant="primary">Ajouter</Button>
                    <Button
                        type="button"
                        variant="success"
                        className="position-relative"
                        disabled={!isDirty || isFetching}
                        onClick={saveRegulation}
                    >
                        <span className={isFetching ? 'invisible' : ''}>Sauvegarder</span>
                        <span className={'position-absolute translate-middle top-50 start-50'}>
                            <Spinner
                                animation="border"
                                variant="primary"
                                role="status"
                                size='sm'
                                className={!isFetching ? 'invisible' : ''}
                            />
                        </span>
                    </Button>
                    <Button
                        type="button"
                        variant="danger"
                        disabled={!isDirty || isFetching}
                        onClick={resetRegulation}
                    >
                        Annuler
                    </Button>
                </div>
            </form>
            {Object.entries(errors).map(([key, error]) => (
                <Alert key={key} variant="danger" className="m-4">{error}</Alert>
            ))}
            <Container fluid>
                <Row className="flex-nowrap" style={{ overflowX: "scroll" }}>
                    {actionTendencies.map((t) => (
                        <Col key={t.code} xs={12} lg={6} xxl={4}>
                            <RegulationCard actionTendency={t} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelRegulation);
