import { getService } from "../../store/api";

const getMetrics = (model, version, start, end, interval, category) => async (dispatch, getState) => {
    const service = getService(getState());
    let metrics = [];
    try {
        const url = new URL(`https://api/v1/metrics/`);
        if (model) {
            url.searchParams.append('model', model.id);
        }
        if (version) {
            url.searchParams.append('major', version.major);
            url.searchParams.append('minor', version.minor);
        }
        url.searchParams.append('start', start.toISOString());
        if (end) {
            url.searchParams.append('end', end.toISOString());
        }
        url.searchParams.append('interval', interval);
        if (category) {
            url.searchParams.append('category', category);
        }
        const response = await service.fetchEndpoint(url.pathname.substring(1) + url.search, 'GET');
        metrics = await response.json();
    } catch (response) {
    }
    return metrics;
};

export default getMetrics;
