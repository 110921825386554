import { OidcProvider } from "@axa-fr/react-oidc";
import AuthApp from "./AuthApp";
import AuthModal from "./components/AuthModal";

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  scope: process.env.REACT_APP_OIDC_SCOPE,
  storage: localStorage,
  service_worker_relative_url: null,
  service_worker_only: false,
};

const AuthWrapper = () => {
  return (
    <OidcProvider
      configuration={oidcConfig}
      loadingComponent={() => <AuthModal>Chargement en cours ...</AuthModal>}
      authenticatingErrorComponent={() => <AuthModal reload={true}>Erreur d'authentification</AuthModal>}
      authenticatingComponent={() => <AuthModal>Authentification en cours ...</AuthModal>}
      sessionLostComponent={() => <AuthModal reload={true}>Session expirée</AuthModal>}
      callbackSuccessComponent={() => <AuthModal success={true}>Authentification réussie</AuthModal>}
    >
      <AuthApp />
    </OidcProvider>
  );
};

export default AuthWrapper;
