import { getRegulationActionTendencies } from "../../store/regulation";
import { SET_OUTPUTS } from "./types";

const deleteOutput = (code, emotion) => async (dispatch, getState) => {
    const actionTendency = getRegulationActionTendencies(getState()).find(t => t.code === code);
    const outputs = actionTendency.outputs.filter(o => o.emotion !== emotion);
    dispatch({ type: SET_OUTPUTS, code, outputs });
};

export default deleteOutput;
