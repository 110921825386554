import { useRef, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import addUtterance from '../actions/utterances/add';
import predictVersion from '../actions/versions/predict';
import { getUtterancesErrors } from '../store/utterances';
import Alert from './Alert';

const mapStateToProps = (state) => {
    return {
        apiErrors: getUtterancesErrors(state),
    };
};

const mapDispatchToProps = {
    predictVersion,
    addUtterance,
};

const UtteranceAddRow = ({ apiErrors, predictVersion, addUtterance }) => {
    const [isFetching, setIsFetching] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const bottomRef = useRef(null);

    const onSubmit = async (formData) => {
        if (!formData.utterance || isFetching) {
            return;
        }
        setIsFetching(true);
        try {
            const predicted = await predictVersion({ utterance: formData.utterance });
            if (predicted !== null) {
                const utterance = await addUtterance({
                    utterance: predicted.utterance,
                    emotion: predicted.emotion,
                });
                if (utterance !== null) {
                    setValue('utterance', '');
                    bottomRef?.current?.scrollIntoView?.();
                }
            }
        }
        finally {
            setIsFetching(false);
        }
    };

    return (
        <tr>
            <td>
                <form onSubmit={handleSubmit(onSubmit)} className="d-flex align-items-center gap-2">
                    <Form.Control {...register("utterance", { required: true })}
                        type="text"
                        placeholder="Nouvelle phrase"
                        aria-label="Nouvelle phrase"
                    />
                </form>
                <div ref={bottomRef}></div>
            </td>
            <td colSpan={1000}>
                {isFetching && (
                    <Spinner animation="border" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )}
                <Alert message={apiErrors.utterance} />
                <Alert message={errors.utterance?.message} />
            </td>
        </tr>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UtteranceAddRow);
