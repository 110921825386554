import { useOidc } from "@axa-fr/react-oidc";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import setIsReconnecting from "../actions/api/setIsReconnecting";
import { getIsReconnecting } from "../store/api";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        isReconnecting: getIsReconnecting(state),
    };
};

const mapDispatchToProps = {
    setIsReconnecting,
};

const AuthModal = ({ children, reload, success, isReconnecting, setIsReconnecting }) => {
    const oidc = useOidc();

    const onReload = async () => {
        await oidc.logout();
        window.location.reload(false);
    };

    useEffect(() => {
        if (reload && !isReconnecting) {
            setIsReconnecting(true);
            onReload();
        }
    }, [reload, isReconnecting, setIsReconnecting]);

    useEffect(() => {
        if (success) {
            setIsReconnecting(false);
        }
    }, [success, setIsReconnecting]);

    if (reload && !isReconnecting) {
        return <></>;
    }

    return (
        <Modal show={true} centered>
            <Modal.Header>
                <Modal.Title>Retorik Personality</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {reload &&
                <Modal.Footer>
                    <Button onClick={onReload}>Réessayer</Button>
                </Modal.Footer>
            }
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
