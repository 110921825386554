import { useEffect } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import deleteOutput from "../actions/regulation/deleteOutput";
import setOutput from "../actions/regulation/setOutput";
import emotions, { compareEmotions } from "../data/emotions";

const mapDispatchToProps = {
    deleteOutput,
    setOutput,
};

const RegulationOutputs = ({ actionTendency, deleteOutput, setOutput }) => {
    const { register, reset, getValues, handleSubmit } = useForm();

    const onSubmit = (formData) => {
        if (!formData?.emotion) {
            return;
        }
        setOutput(actionTendency.code, formData.emotion, 0);
    };

    useEffect(() => {
        if (
            getValues('emotion') === '' ||
            actionTendency.outputs.find(o => o.emotion === getValues('emotion')) !== undefined
        ) {
            reset();
        }
    }, [actionTendency, getValues, reset]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex my-3">
                <Form.Select {...register("emotion")}>
                    {
                        Object.entries(emotions)
                            .filter(([id]) => actionTendency.outputs.map(o => o.emotion).find(e => e === id) === undefined)
                            .sort(([idA], [idB]) => compareEmotions(idA, idB))
                            .map(([id, { label }]) => <option key={id} value={id}>{label}</option>)
                    }
                </Form.Select>
                <Button type="submit" variant="primary" className="ms-2">Ajouter</Button>
            </form>
            <div>
                <ListGroup>
                    {actionTendency.outputs.map(output => (
                        <ListGroup.Item key={output.emotion} className="py-1">
                            <Form.Group className="d-flex gap-1 align-items-center" controlId={"output" + actionTendency.code + output.emotion}>
                                <Form.Label className="m-0 flex-grow-1">{emotions[output.emotion].label}</Form.Label>
                                <div className="w-50 d-flex">
                                    <Form.Control
                                        type="number"
                                        size="sm"
                                        value={output.value}
                                        step="0.1"
                                        className="w-0 flex-grow-1"
                                        onChange={e => setOutput(actionTendency.code, output.emotion, parseFloat(e.target.value) || 0)}
                                    />
                                    <Button
                                        type="button"
                                        variant="danger"
                                        size="sm"
                                        onClick={() => deleteOutput(actionTendency.code, output.emotion)}
                                    >-</Button>
                                </div>
                            </Form.Group>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        </>
    );
};

export default connect(null, mapDispatchToProps)(RegulationOutputs);
