import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { FETCH_UTTERANCES__BEGIN, FETCH_UTTERANCES__RESOLVE } from "./types";

const fetchUtterances = (offset, limit, sort) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return [];
    }

    dispatch({ type: FETCH_UTTERANCES__BEGIN, offset, limit });

    const service = getService(getState());
    let errors = {};
    let utterances = [];
    let totalCount = 0;
    try {
        let uri =
            `v1/model/${model.id}/` +
            `version/${version.major}.${version.minor}` +
            `/utterance/?offset=${offset}`
            ;
        if (limit > 0) {
            uri += `&limit=${limit}`;
        }

        sort.forEach(s => { uri += `&orderBy=${s['id']},${s['desc'] ? 'DESC' : 'ASC'}` });

        const response = await service.fetchEndpoint(uri, 'GET');
        totalCount = response.headers.get('x-total-count');
        utterances = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'utterances');
    }

    dispatch({ type: FETCH_UTTERANCES__RESOLVE, utterances, errors, totalCount });
    return utterances;
};

export default fetchUtterances;
