import { Alert as BsAlert } from 'react-bootstrap'

const Alert = ({message, variant = "danger d-flex align-items-center mt-1"}) => {
    return (
        <>
            {message && (
                <BsAlert variant={variant} role="alert">
                    {message}
                </BsAlert>
            )}
        </>
    )
};

export default Alert;
