import { OidcSecure, useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import setAccount from "./actions/api/setAccount";
import setAccounts from "./actions/api/setAccounts";
import App from "./App";
import AuthModal from "./components/AuthModal";
import fetchAccounts from "./services/fetchAccounts";
import { clearAccount, getAccount as storageGetAccount, setAccount as storageSetAccount } from "./services/getAccount";
import { getAccount } from "./store/api";

const mapStateToProps = (state) => {
    return {
        account: getAccount(state),
    };
};

const mapDispatchToProps = {
    setAccount,
    setAccounts,
};

function AuthApp({ account, setAccount, setAccounts }) {
    const { isAuthenticated } = useOidc();
    const { accessToken } = useOidcAccessToken();
    const [managementError, setManagementError] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            setAccounts([]);
            return;
        }
        (async () => {
            setManagementError(false);
            const accounts = await fetchAccounts();
            if (!accounts || !accounts.length) {
                setManagementError(true);
                setAccounts([]);
                return;
            }
            setAccounts(accounts);

            let idAccount = storageGetAccount();
            if (!idAccount) {
                const defaultAccount = accounts[0].idAccount.toString();
                if (defaultAccount) {
                    storageSetAccount(defaultAccount);
                    idAccount = defaultAccount;
                } else {
                    clearAccount();
                    return;
                }
            }

            const account = accounts.find(a => a.idAccount.toString() === idAccount);
            setAccount(account);
        })();
    }, [isAuthenticated, accessToken, setAccount, setAccounts]);

    return (
        <OidcSecure>
            {account && <App />}
            {managementError && <AuthModal reload={true}>Erreur d'authentification</AuthModal>}
        </OidcSecure>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthApp);
