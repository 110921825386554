import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getSelectedVersion } from "../../store/versions";
import { TRAIN_VERSION__BEGIN, TRAIN_VERSION__RESOLVE } from "./types";

const trainVersion = (force = false) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return null;
    }

    dispatch({ type: TRAIN_VERSION__BEGIN });

    const service = getService(getState());
    let errors = {};
    let train = null;
    try {
        let uri =
            `v1/model/${model.id}/` +
            `version/${version.major}.${version.minor}` +
            `/train`
            ;
        if (force) {
            uri += '?force=1'
        }
        const response = await service.fetchEndpoint(uri, 'POST');
        train = await response.status;
    } catch (response) {
        train = await response.status;
        if (await response.status === 406) { // not acceptable
            errors = { records: await response.json() };
        } else {
            errors = await service.getErrors(response, 'version');
        }
    }

    dispatch({ type: TRAIN_VERSION__RESOLVE, train, errors });
    return { train, errors };
};

export default trainVersion;
