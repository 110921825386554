import { Button, Modal } from 'react-bootstrap'

const ModelTrainModal = ({ show, onConfirm, onCancel, errors }) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Entrainement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.records !== undefined &&
                    ((errors.records.length ?? 0) > 0 ?
                        <>
                            Ce modèle est déjà en cours d'entrainement. Continuer ?<br /><br />
                            {errors.records.map((r, index) => (
                                <div key={index}>
                                    Démarré le {(new Date(r.created_at)).toLocaleString()} par {r.user_name}<br />
                                </div>
                            ))}
                        </> :
                        <>L'entrainement de ce modèle est déjà à jour. Continuer ?</>
                    )
                }
                {errors.utterances !== undefined &&
                    <>Ce modèle ne contient pas suffisement de phrases de formation. Continuer ?</>
                }
                {errors.records === undefined && errors.utterances === undefined &&
                    <>Une erreur est survenue. Réessayer ?</>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Annuler</Button>
                <Button variant="primary" onClick={onConfirm}>Confirmer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModelTrainModal;
