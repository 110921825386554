export const ADD_UTTERANCE__BEGIN = 'ADD_UTTERANCE__BEGIN';
export const ADD_UTTERANCE__RESOLVE = 'ADD_UTTERANCE__RESOLVE';
export const DELETE_UTTERANCE__BEGIN = 'DELETE_UTTERANCE__BEGIN';
export const DELETE_UTTERANCE__RESOLVE = 'DELETE_UTTERANCE__RESOLVE';
export const EDIT_UTTERANCE__BEGIN = 'EDIT_UTTERANCE__BEGIN';
export const EDIT_UTTERANCE__RESOLVE = 'EDIT_UTTERANCE__RESOLVE';
export const FETCH_UTTERANCES__BEGIN = 'FETCH_UTTERANCES__BEGIN';
export const FETCH_UTTERANCES__RESOLVE = 'FETCH_UTTERANCES__RESOLVE';
export const INVALIDATE_UTTERANCE_SCORE = 'INVALIDATE_UTTERANCE_SCORE';
export const SCORE_UTTERANCE__BEGIN = 'SCORE_UTTERANCE__BEGIN';
export const SCORE_UTTERANCE__RESOLVE = 'SCORE_UTTERANCE__RESOLVE';
