import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import refreshVersion from '../actions/versions/refresh';
import trainStatus from '../data/trainStatus';
import { getNotifications } from '../store/notifications';
import { getSelectedVersion } from '../store/versions';

const mapStateToProps = (state) => {
    return {
        selectedVersion: getSelectedVersion(state),
        notifications: getNotifications(state),
    };
};

const mapDispatchToProps = {
    refreshVersion,
};

const ModelTrainList = ({ notifications, refreshVersion, selectedVersion }) => {
    useEffect(() => {
        refreshVersion();
    }, [notifications, refreshVersion]);

    return (
        <div className="table-responsive">
            {(selectedVersion?.train_records?.length ?? 0) === 0 ? <div className='px-4'>Aucun entrainement effectué</div> :
                <table id="utterance-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                    <thead>
                        <tr className="light">
                            <th>Créé le</th>
                            <th>Utilisateur</th>
                            <th>État</th>
                            <th>Modifié le</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedVersion.train_records.map((r, index) => (
                            <tr key={index}>
                                <td>{(new Date(r.created_at).toLocaleString())}</td>
                                <td>{r.user_name}</td>
                                <td>{trainStatus[r.status]?.label ?? 'Inconnu'}</td>
                                <td>{(new Date(r.updated_at).toLocaleString())}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelTrainList);
