import { getRegulationActionTendencies } from "../../store/regulation";
import { SET_INPUTS } from "./types";

const deleteInput = (code, emotion) => async (dispatch, getState) => {
    const actionTendency = getRegulationActionTendencies(getState()).find(t => t.code === code);
    const inputs = actionTendency.inputs.filter(i => i.emotion !== emotion);
    dispatch({ type: SET_INPUTS, code, inputs });
};

export default deleteInput;
