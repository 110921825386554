import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// model
import Dashboard from '../../app/dashboard';
import Model from '../../app/model';
import ModelList from '../../app/model-list';
import ModelAdd from '../../app/model-add';
import ModelEdit from '../../app/model-edit';

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

//admin
// import Admin from '../views/dashboard/admin/admin';

const DefaultRouter = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Switch>
                    <Route path="/" exact component={() => <Redirect to="/dashboard" />} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/signin-oidc" exact component={() => <Redirect to="/" />} />
                    <Route path="/signout-callback-oidc" exact component={() => <Redirect to="/" />} />
                    {/* models */}
                    <Route path="/dashboard/app/models" exact component={ModelList} />
                    <Route path="/dashboard/app/models/:id" exact component={Model} />
                    <Route path="/dashboard/app/models/:id/edit" exact component={ModelEdit} />
                    <Route path="/dashboard/app/model-add" exact component={ModelAdd} />

                    {/*admin*/}
                    {/* <Route path="/dashboard/admin/admin" exact component={Admin}/> */}
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default DefaultRouter
