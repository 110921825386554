import { getRegulationActionTendencies } from "../../store/regulation";
import { SET_INPUTS } from "./types";

const deletePoint = (code, emotion, pointIndex) => async (dispatch, getState) => {
    const actionTendency = getRegulationActionTendencies(getState()).find(t => t.code === code);

    let inputs;
    if (pointIndex === 0 && actionTendency.inputs.find(i => i.emotion === emotion)?.points?.length === 1) {
        inputs = actionTendency.inputs.filter(i => i.emotion !== emotion);
    } else {
        inputs = actionTendency.inputs.map(i => i.emotion !== emotion ? i : ({
            ...i,
            points: i.points.filter((p, index) => index !== pointIndex),
        }));
    }
    dispatch({ type: SET_INPUTS, code, inputs });
};

export default deletePoint;
