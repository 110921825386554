import { getService } from "../../store/api";
import { getSelectedModel } from "../../store/models";
import { getUtterances } from "../../store/utterances";
import { getSelectedVersion } from "../../store/versions";
import { INVALIDATE_UTTERANCE_SCORE, SCORE_UTTERANCE__BEGIN, SCORE_UTTERANCE__RESOLVE } from "./types";

export const scoreUtterance = (utterance) => async (dispatch, getState) => {
    const model = getSelectedModel(getState());
    const version = getSelectedVersion(getState());
    if (!model || !version) {
        return null;
    }

    dispatch({ type: SCORE_UTTERANCE__BEGIN, utterance });

    const service = getService(getState());
    let errors = {};
    let predict = null;
    try {
        const uri =
            `v1/model/${model.id}/` +
            `version/${version.major}.${version.minor}` +
            `/predict`
            ;
        const body = {
            utterance: utterance.utterance,
        };
        const response = await service.fetchEndpoint(uri, 'POST', body, true);
        predict = await response.json();
    } catch (response) {
        errors = await service.getErrors(response, 'utterance');
    }

    dispatch({ type: SCORE_UTTERANCE__RESOLVE, utterance, predict, errors });
    return predict;
};

export const scoreNextUtterance = () => (dispatch, getState) => {
    const utterances = getUtterances(getState());

    const scoring = utterances.find(u => u?.isScoring === true);
    if (scoring !== undefined) return;

    const utterance = utterances.find(u => typeof u?.score !== 'number' && u?.scoringFailed !== true);
    if (utterance === undefined) return;

    scoreUtterance(utterance)(dispatch, getState);
};

export const invalidateUtteranceScore = (utterance) => async (dispatch) => {
    dispatch({ type: INVALIDATE_UTTERANCE_SCORE, utterance });
};

export default scoreUtterance;
