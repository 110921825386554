import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux';
import fetchModels from '../actions/models/fetch'
import { getAccount } from '../store/api'
import ModelMetrics from '../components/ModelMetrics'

const mapStateToProps = (state) => {
	return {
		account: getAccount(state),
	};
};

const mapDispatchToProps = {
	fetchModels,
};

const Dashboard = ({ account, fetchModels }) => {
	useEffect(() => {
		fetchModels(0, 0);
	}, [account, fetchModels]);

	return (
		<Row>
			<Col sm="12">
				<div className="d-flex justify-content-between align-items-center">
					<h4 className="my-3">Statistiques</h4>
				</div>
				<ModelMetrics />
			</Col>
		</Row>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
