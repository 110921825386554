import {createStore as reduxCreateStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk';
import api from './api';
import models from './models';
import notifications from './notifications';
import regulation from './regulation';
import utterances from './utterances';
import versions from './versions';

import Mode from '../HopeUI/store/setting/setting'

const createStore = () => reduxCreateStore(
    combineReducers({
        mode: Mode,
        api,
        models,
        notifications,
        regulation,
        utterances,
        versions,
    }),
    applyMiddleware(thunk)
);

export default createStore;
