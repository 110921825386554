import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import ModelForm from '../components/ModelForm'
import { connect } from 'react-redux';
import { getModels, getModelsErrors } from '../store/models'
import addModel from '../actions/models/add';
import fetchModels from '../actions/models/fetch'

const mapStateToProps = (state) => {
   return {
      baseModels: getModels(state),
      errors: getModelsErrors(state),
   };
};

const mapDispatchToProps = {
   addModel,
   fetchModels,
};

const ModelAdd = ({ addModel, baseModels, errors, fetchModels }) => {
   const history = useHistory();

   useEffect(() => {
      fetchModels(0, null);
   }, [fetchModels]);

   const onSubmit = formData => {
      const body = {
         name: formData.name,
         base_model: formData.base_model !== '0' ? formData.base_model : null,
      };
      addModel(body).then((result) => {
         if (result !== null) {
            history.push('models');
         }
      });
   };

   const onCancel = () => {
      history.push('models');
   };

   return (
      <Row>
         <Col xl="12">
            <ModelForm
               title="Ajouter un modèle"
               submitText="Créer le modèle"
               apiErrors={errors}
               baseModels={baseModels}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
         </Col>
      </Row>
   );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelAdd);
