import { clearAccount, setAccount as storageSetAccount } from "../../services/getAccount";
import { getAccount } from "../../store/api";
import { SET_ACCOUNT } from "./types";

const setAccount = (account) => async (dispatch, getState) => {
    if (account) {
        storageSetAccount(account.idAccount.toString());
    } else {
        clearAccount();
    }

    if (getAccount(getState())?.idAccount !== account?.idAccount) {
        dispatch({ type: SET_ACCOUNT, account });
    }
};

export default setAccount;
